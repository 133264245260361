import React, { useState, useMemo } from "react";
import {
    TextField,
    Checkbox,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Pagination,
    Box,
    Button,
    Popover,
    Tooltip,
} from "@mui/material";
import { CalendarToday, ExpandMore } from "@mui/icons-material";
import moment from "moment";

const TherapyTable = ({ data, handleSelectRow, selectedRows, dateRange, handleSetDateRange }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState("");
    const [expandedRow, setExpandedRow] = useState(null);
    const rowsPerPage = 7;

    // Parse "Value" field
    const parseValueField = (valueField) => {
        const parsedValues = {};
        if (valueField && typeof valueField === "string") {
            valueField.split(",").forEach((item) => {
                const [key, val] = item.split(":");
                parsedValues[key.trim()] = val?.trim();
            });
        }
        return parsedValues;
    };

    const processedData = useMemo(() => {
        return data.map((row) => ({
            ...row,
            ...parseValueField(row.Value),
        }));
    }, [data]);

    const filteredData = useMemo(() => {
        return processedData.filter((row) => {
            const matchesSearch =
                row["Event ID"]
                    ?.toString()
                    .toLowerCase()
                    .includes(searchQuery.toLowerCase()) ||
                row["Event Type"]?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                row["Log Type"]?.toLowerCase().includes(searchQuery.toLowerCase());

            const matchesFromDate = dateRange.from
                ? moment(row["Time Stamp"], "YYYY-MM-DD").isSameOrAfter(moment(dateRange.from, "YYYY-MM-DD"))
                : true;

            const matchesToDate = dateRange.to
                ? moment(row["Time Stamp"], "YYYY-MM-DD").isSameOrBefore(moment(dateRange.to, "YYYY-MM-DD"))
                : true;

            return matchesSearch && matchesFromDate && matchesToDate;
        });
    }, [processedData, searchQuery, dateRange]);

    const currentPageData = useMemo(() => {
        const startIndex = (currentPage - 1) * rowsPerPage;
        return filteredData.slice(startIndex, startIndex + rowsPerPage);
    }, [filteredData, currentPage]);

    const handlePageChange = (event, page) => {
        setCurrentPage(page);
    };

    // const handleDateRangeChange = ({ from, to }) => {
    //     setDateRange({ from, to });
    // };

    const DateRangePicker = ({ onDateRangeSelect }) => {
        const [anchorEl, setAnchorEl] = useState(null);
        const [localRange, setLocalRange] = useState({ from: dateRange.from, to: dateRange.to });

        const handleSubmit = () => {
            onDateRangeSelect({ from: localRange.from, to: localRange.to });
            setAnchorEl(null);
        };
        const handleClear = () => {
            onDateRangeSelect({ from: null, to: null });
            setAnchorEl(null);
        };

        return (
            <div>
                <Button
                    style={{ margin: 0, padding: 0 }}
                    // variant="contained"
                    onClick={(e) => setAnchorEl(e.currentTarget)}
                    sx={{
                        // backgroundColor: "#1461D3",
                        color: "#1461D3",
                        // "&:hover": { backgroundColor: "#104eb2" },
                    }}
                >
                    <CalendarToday />
                </Button>
                <Popover
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={() => setAnchorEl(null)}
                    anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                >
                    <Box sx={{ p: 2, width: "300px" }}>
                        <Typography variant="h6" sx={{ mb: 2, fontSize: "16px" }}>
                            Select Date Range
                        </Typography>
                        <TextField
                            type="date"
                            label="From"
                            fullWidth
                            value={localRange.from || ""}
                            onChange={(e) =>
                                setLocalRange({ ...localRange, from: e.target.value })
                            }
                            InputLabelProps={{ shrink: true }}
                            sx={{ mb: 2 }}
                        />
                        <TextField
                            type="date"
                            label="To"
                            fullWidth
                            value={localRange.to || ""}
                            onChange={(e) =>
                                setLocalRange({ ...localRange, to: e.target.value })
                            }
                            InputLabelProps={{ shrink: true }}
                        />
                        <div className="flex">
                            <Button
                                variant="outlined"
                                fullWidth
                                sx={{ m: 2 }}
                                onClick={handleClear}
                                disabled={(!localRange.from) && (!localRange.to)}
                            >
                                Clear
                            </Button>
                            <Button
                                variant="contained"
                                fullWidth
                                sx={{ m: 2 }}
                                onClick={handleSubmit}
                                disabled={(!localRange.from) && (!localRange.to)}
                            >
                                Apply
                            </Button></div>
                    </Box>
                </Popover>
            </div>
        );
    };

    return (
        <Box
            sx={{
                p: 2,
                border: "1px solid #ddd",
                borderRadius: "8px",
                backgroundColor: "#fff",
            }}
        >
            {/* Search and Filters */}
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row", // Arrange elements in a single row
                    gap: 2,

                    // p: 2,
                    boxShadow: "0px 4px 10px rgba(0,0,0,0.1)",
                    borderRadius: "12px",
                    backgroundColor: "#f8f9fa",
                    alignItems: "center", // Ensure vertical alignment
                    flexWrap: "wrap", // Ensure responsiveness for smaller screens
                }}
            >
                <TextField
                    variant="outlined"
                    placeholder="Search..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    fullWidth
                    sx={{
                        flex: 1, // Allows the search bar to take available space
                        "& .MuiOutlinedInput-root": {
                            borderRadius: "8px",
                        },
                    }}
                />
                <DateRangePicker onDateRangeSelect={handleSetDateRange} />
            </Box>

            {/* Table */}
            <Box>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        fontSize: "80%",
                        gap: 1.5,
                        boxShadow: "inherit",
                        marginRight: 3,
                        margin: 2,
                        fontFamily: "fangsong",
                        flexGrow: 1,
                    }}
                >
                    <p>#</p>
                    <p>ID</p>
                    <p>Cycles</p>
                    <p>MODE</p> <p>Time</p>
                </Box>
                {currentPageData.length > 0 ? (
                    currentPageData.map((row, index) => (
                        <Accordion
                            key={index}
                            expanded={expandedRow === row["Event ID"]}
                            onChange={() =>
                                setExpandedRow(
                                    expandedRow === row["Event ID"] ? null : row["Event ID"]
                                )
                            }
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                                sx={{
                                    backgroundColor:
                                        expandedRow === row["Event ID"] ? "#f0f8ff" : "#ffffff",
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: 1,
                                        flexGrow: 1,
                                    }}
                                >
                                    <Checkbox
                                        style={{ padding: 0, margin: 0 }}
                                        checked={selectedRows.includes(row["Event ID"])}
                                        onChange={(e) => {
                                            handleSelectRow(row);
                                        }} />
                                    <small>{row["Event ID"]}</small>
                                    <small>{row["N_C"]}</small>
                                    <small>{row["MODE"]}</small>{" "}
                                    <small style={{ fontSize: "70%" }}>
                                        {moment(row["Time Stamp"]).format("DD-MM-YYYY HH:mm:ss")}
                                    </small>
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box
                                    sx={{
                                        width: "100%",
                                        borderRadius: "8px",
                                        overflow: "hidden",
                                        border: "1px solid #ddd",
                                    }}
                                >
                                    {/* Header Row */}
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            backgroundColor: "#f0f0f0",
                                            padding: "8px 16px",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                flex: 1,
                                                textAlign: "right",
                                                fontSize: "85%",
                                                fontWeight: "bold",
                                            }}
                                        >
                                            Pressure
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                flex: 1,
                                                textAlign: "right",
                                                fontSize: "85%",
                                                fontWeight: "bold",
                                            }}
                                        >
                                            Duration
                                        </Typography>
                                    </Box>

                                    {/* Data Rows */}
                                    {["Pause", "Inspiratory", "Expiratory"].map(
                                        (label, index) => (
                                            <Box
                                                key={label}
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    padding: "8px 16px",
                                                    backgroundColor:
                                                        index % 2 === 0 ? "#f9f9f9" : "#ffffff", // Zebra striping
                                                    borderBottom: "1px solid #ddd",
                                                }}
                                            >
                                                <Typography
                                                    variant="body2"
                                                    sx={{
                                                        flex: 1,
                                                        textAlign: "left",
                                                        fontWeight: "bold",
                                                        color: "#555",
                                                    }}
                                                >
                                                    {label}
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    sx={{ flex: 1, textAlign: "right", color: "#333" }}
                                                >
                                                    {
                                                        row[
                                                        `${label === "Pause"
                                                            ? "P_P"
                                                            : label === "Inspiratory"
                                                                ? "I_P"
                                                                : "E_P"
                                                        }`
                                                        ]
                                                    }
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    sx={{ flex: 1, textAlign: "right", color: "#333" }}
                                                >
                                                    {(
                                                        row[
                                                        `${label === "Pause"
                                                            ? "P_T"
                                                            : label === "Inspiratory"
                                                                ? "I_T"
                                                                : "E_T"
                                                        }`
                                                        ] / 10
                                                    ).toFixed(1)}
                                                </Typography>
                                            </Box>
                                        )
                                    )}
                                </Box>

                                {Object.entries(row)
                                    .filter(([key]) => !key.endsWith("_P") && !key.endsWith("_T")) // Exclude Pressure/Duration Keys
                                    .map(([key, value], index) => (
                                        <Box
                                            key={key}
                                            sx={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                padding: "4px 8px", // Reduced padding for compact layout
                                                borderBottom: "1px solid #ddd",
                                            }}
                                        >
                                            <Tooltip title={key.replace(/_/g, " ")} arrow>
                                                <Typography
                                                    variant="body2"
                                                    sx={{
                                                        flex: 1,
                                                        fontWeight: "bold",
                                                        color: "#555",
                                                        whiteSpace: "nowrap",
                                                        overflow: "hidden",
                                                        textOverflow: "ellipsis",
                                                        fontSize: "75%", // Compact font size
                                                    }}
                                                >
                                                    {key.replace(/_/g, " ")}
                                                </Typography>
                                            </Tooltip>
                                            <Tooltip title={value} arrow>
                                                <Typography
                                                    variant="body2"
                                                    sx={{
                                                        flex: 2,
                                                        textAlign: "right",
                                                        color: "#333",
                                                        whiteSpace: "nowrap",
                                                        overflow: "hidden",
                                                        textOverflow: "ellipsis",
                                                        fontSize: "75%", // Compact font size
                                                    }}
                                                >
                                                    {value}
                                                </Typography>
                                            </Tooltip>
                                        </Box>
                                    ))}

                            </AccordionDetails>
                        </Accordion>
                    ))
                ) : (
                    <Typography textAlign="center" color="textSecondary">
                        No data available
                    </Typography>
                )}
            </Box>

            {/* Pagination */}
            <Box sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
                <Pagination
                    count={Math.ceil(filteredData.length / rowsPerPage)}
                    page={currentPage}
                    onChange={handlePageChange}
                    variant="outlined"
                    shape="rounded"
                    color="primary"
                />
            </Box>
        </Box>
    );
};

export default TherapyTable;
