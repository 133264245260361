export const ShimmerProgressBar = ({ uploadProgress }) => (
    <>
        <style>
            {`
                @keyframes shimmer {
                    0% {
                        background-position: 200% 0;
                    }
                    100% {
                        background-position: -200% 0;
                    }
                }
            `}
        </style>
        <div
            style={{
                height: "8px",
                borderRadius: "4px",
                backgroundColor: "#f0f4fd",
                margin: "20px",
                marginRight: "5px",
                overflow: "hidden", // Ensures inner content doesn't overflow
                position: "relative", // Allows inner content positioning
                width: "95%"
            }}
        >
            <div
                role="progressbar"
                style={{
                    width: `${uploadProgress}%`, // Dynamically set width based on progress
                    height: "100%", // Full height of parent
                    background: `
                        linear-gradient(
                            to right,
                            #c5e86c,
                            #ff8f1c,
                            #74d1ea,
                            #ff8f1c,
                            #c5e86c
                        )
                    `,
                    backgroundSize: "200% 100%", // Double the width for shimmer effect
                    animation: uploadProgress < 100 ? "shimmer 2s infinite" : "none", // Stop animation at 100%
                    borderRadius: "4px",
                    transition: "width 0.4s ease", // Smooth animation for width
                }}
                aria-valuenow={uploadProgress}
                aria-valuemin="0"
                aria-valuemax="100"
            ></div>
        </div>
    </>
);
