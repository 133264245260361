import React, { useRef, useState, useEffect } from "react";
import TherapyTable from "./therapy-table";
import NavBar from "./navbar";
import MultiSubplotChart from "./group";
import { Drawer, Button, Box, Typography, CircularProgress } from "@mui/material";
import { Download, FilterList, FileDownload, Report, CheckBox } from "@mui/icons-material";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { CloseOutlined } from "@mui/icons-material";
import { Checkbox, FormControlLabel } from "@mui/material";
import logoR from './abm.png'
import logoL from './arc.png'
import { useHistory } from "react-router-dom";

export const ReportPage = () => {
    const [activeTab, setActiveTab] = useState("Overview");
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [data, setData] = useState([]);
    const [graphData, setGraphData] = useState(null);
    const [averageValues, setAverageValues] = useState(null);
    const [selectedRows, setSelectedRows] = useState([]);
    const [loadingCsv, setLoadingCsv] = useState(false);
    const [loadingAutoPdf, setLoadingAutoPdf] = useState(false);
    const [hidePreview, setHidePreview] = useState(false); // Toggle for hidePreview
    const [dateRange, setDateRange] = useState({ from: null, to: null });
    const childRef = useRef(); // Create a ref for the child component
    const history = useHistory();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const filename = queryParams.get("uuid");
    const serialNumber = queryParams.get("id");

    useEffect(() => {
        setIsDrawerOpen(activeTab === "Overview");
    }, [activeTab]);
    const baseUrl = "https://data1.arc.abmrc.com"
    // const baseUrl = "http://localhost:5000"
    useEffect(() => {
        if (filename) {
            axios
                .get(`${baseUrl}/load-data?filename=${filename}`)
                .then((response) => {
                    const therapyData = JSON.parse(response?.data?.data?.therapy_start);
                    setDateRange({ from: therapyData[0]["Time Stamp"]?.split(" ")[0] ?? null, to: therapyData[therapyData.length - 1]["Time Stamp"]?.split(" ")[0] ?? null })
                    setData([...therapyData]);
                    getGraphPoints([])
                })
                .catch((error) => console.error("Error fetching data:", error));
        }
    }, [filename]);

    const handleSelectRow = (row) => {
        setSelectedRows((prevSelected) => {
            const newRows = prevSelected.includes(row["Event ID"])
                ? prevSelected.filter((id) => id !== row["Event ID"])
                : [...prevSelected, row["Event ID"]];

            getGraphPoints(newRows)

            return newRows;
        });
    };
    const getGraphPoints = (newRows, dateSelected = { from: null, to: null }) => {
        let url = `${baseUrl}/get-graph-points?uuid=${filename}&selected_rows=${newRows}`;

        if (dateSelected.from) {
            url += `&from_date=${encodeURIComponent(dateSelected.from)}`;
        }

        if (dateSelected.to) {
            url += `&to_date=${encodeURIComponent(dateSelected.to)}`;
        }
        axios
            .get(
                `${url}`
            )
            .then((response) => {
                const therapyGraphData = response?.data?.merged_data;
                setGraphData({ ...therapyGraphData });
                axios
                    .get(
                        `${baseUrl}/get-summary?uuid=${filename}`
                    )
                    .then((response) => {
                        setAverageValues(response?.data)
                    }).catch((error) => console.error("Error fetching Average Summary:", error));

                // setDateRange({ from: therapyGraphData["Cycle_E_P"][0]?.x[0], to: therapyGraphData["Cycle_E_P"][0]?.x[therapyGraphData["Cycle_E_P"][0]?.x.length - 1] })
            })
            .catch((error) => console.error("Error fetching graph data:", error));
    }
    const handleButtonClick = () => {
        if (childRef.current) {
            childRef.current.callChildFunction();
        }
    };
    const handleAutoGeneratePdf = async () => {
        setLoadingAutoPdf(true);
        try {
            await handleButtonClick()
        } catch (error) {
            console.error("Error generating PDF report:", error);
            // alert("Failed to generate PDF report. Please try again.");
        }
    };

    const handleTogglePreview = () => {
        setHidePreview((prevState) => !prevState);
    };
    const handleSetDateRange = ({ from, to }) => {
        setDateRange({ from, to })
        getGraphPoints(selectedRows, { from, to })
    }
    return (
        <>
            {/* <NavBar activeTab={activeTab} setActiveTab={setActiveTab} /> */}
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "16px", backgroundColor: "#ffffff", boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)", borderRadius: "8px" }}>
                {/* Logo */}
                <Box sx={{ display: "flex", alignItems: "center", cursor: "pointer" }} onClick={() => { history.push(`/`) }}
                >
                    <img
                        src={logoL}
                        alt="Logo"
                        style={{ width: "40px", height: "40px", marginRight: "12px" }}
                    />
                    <span style={{ fontSize: "18px", fontWeight: "bold", color: "#333" }}>
                        Arc Connect (Biwaze Cough)
                    </span>
                </Box>
                <Box style={{ textAlign: "center" }}>
                    <Typography
                        variant="h5"
                        component="h5"
                        sx={{ textAlign: "center", flexGrow: 1, color: "#333" }}
                    >
                        {serialNumber}  Therapy Report
                    </Typography>                       <small>
                        {dateRange.from && dateRange.to
                            ? `${dateRange.from} to ${dateRange.to}`
                            : dateRange.from
                                ? `From ${dateRange.from}`
                                : dateRange.to
                                    ? `Up to ${dateRange.to}`
                                    : ''}
                    </small>
                </Box>
                {/* Actions */}
                <Box
                    sx={{
                        display: "flex",
                        gap: 2,
                        alignItems: "center",
                    }}
                >
                    {/* Download CSV Button */}
                    {/* <Button
                        variant="outlined"
                        startIcon={<Download />}
                        onClick={handleDownloadCsv}
                        disabled={loadingCsv}
                        sx={{
                            height: "38px",
                            // borderColor: "#EE9B00",
                            // color: "#EE9B00",
                            padding: "8px 24px",
                            fontSize: "14px",
                            fontWeight: "bold",
                            borderRadius: "8px",
                            transition: "all 0.3s ease",
                            "&:hover": {
                                backgroundColor: "#FFF4E1",
                                borderColor: "#EE9B00",
                            },
                            "&:disabled": {
                                borderColor: "#ddd",
                                color: "#ddd",
                            },
                        }}
                    >
                        Download CSV

                    </Button> */}

                    {/* Auto Generate PDF Button */}
                    {loadingAutoPdf ? (
                        <Button
                            variant="outlined"
                            startIcon={<CircularProgress size={20} sx={{ color: "#94D2BD" }} />}
                            sx={{
                                height: "38px",
                                borderColor: "#94D2BD",
                                padding: "8px 24px",
                                fontSize: "14px",
                                fontWeight: "bold",
                                borderRadius: "8px",
                                transition: "all 0.3s ease",
                                "&:hover": {
                                    backgroundColor: "#EFF8F6",
                                    borderColor: "#94D2BD",
                                },
                                "&:disabled": {
                                    borderColor: "#ddd",
                                    color: "#ddd",
                                },
                            }}
                            disabled
                        >
                            Downloading...
                        </Button>
                    ) : (
                        <Button
                            variant="outlined"
                            startIcon={<FileDownload />}
                            onClick={handleAutoGeneratePdf}
                            sx={{
                                height: "38px",
                                borderColor: "#94D2BD",
                                padding: "8px 24px",
                                fontSize: "14px",
                                fontWeight: "bold",
                                borderRadius: "8px",
                                transition: "all 0.3s ease",
                                "&:hover": {
                                    backgroundColor: "#EFF8F6",
                                    borderColor: "#94D2BD",
                                },
                            }}
                        >
                            Download Report
                        </Button>
                    )}

                </Box>
            </div>

            <Box sx={{ backgroundColor: "#f4f6f9", padding: "20px", minHeight: "80vh" }}>
                <Box
                    sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: 2,
                        alignItems: "center",
                        justifyContent: "space-between",
                        alignConetent: "center",
                        padding: "8px",
                        backgroundColor: "#f9f9f9",
                        borderRadius: "8px",
                        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                    }}
                >
                    <Button
                        variant="outlined"
                        startIcon={<FilterList />}
                        onClick={() => setIsDrawerOpen(true)}
                        aria-label="Open Data Filters"
                        sx={{
                            height: "40px",
                            marginRight: "10px",
                            borderColor: "#0A9396",
                            color: "#0A9396",
                            "&:hover": { backgroundColor: "#E9F5F6", borderColor: "#0A9396" },
                        }}
                    >
                        Data Filters
                    </Button>


                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: 2,
                            margin: "10px",
                        }}
                    >
                        <Box
                            sx={{
                                width: "30px",
                                height: "30px",
                                backgroundImage:
                                    "repeating-linear-gradient(135deg, #1461D3, #1461D3 10px, #ffffff 10px, #ffffff 20px)",
                                border: "1px solid #1461D3",
                                borderRadius: "4px",
                            }}
                        />
                        <Typography>Oscillations Enabled</Typography>
                        <Box
                            sx={{
                                width: "30px",
                                height: "30px",
                                backgroundColor: "#f4f6f9",
                                border: "1px solid #1461D3",
                                borderRadius: "4px",
                            }}
                        />
                        <Typography>Oscillation Disabled</Typography>
                    </Box>
                    <FormControlLabel
                        // style={{ marginRight: "5px" }}
                        control={
                            <Checkbox
                                checked={hidePreview}
                                onChange={handleTogglePreview}

                            />
                        }
                        label="Choose your graph"
                        sx={{
                            transition: "color 0.3s ease", // Smooth transition for label color

                        }}
                    />




                </Box>

                {/* Therapy Table Drawer */}
                <Drawer anchor="left" open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
                    <Box sx={{ width: "300px", padding: "20px" }}>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginBottom: "20px",
                            }}
                        >
                            <Typography variant="h6">Therapy Table</Typography>
                            <CloseOutlined
                                onClick={() => setIsDrawerOpen(false)}
                                sx={{ cursor: "pointer", color: "#333" }}
                            />
                        </Box>
                        {data.length > 0 ? (
                            <TherapyTable
                                dateRange={dateRange}
                                handleSetDateRange={handleSetDateRange}
                                data={data}
                                handleSelectRow={handleSelectRow}
                                selectedRows={selectedRows}
                            />
                        ) : (
                            <Typography>No data available</Typography>
                        )}
                        <Button
                            variant="contained"
                            onClick={() => setIsDrawerOpen(false)}
                            sx={{
                                marginTop: "20px",
                                backgroundColor: "#1461D3",
                                "&:hover": { backgroundColor: "#104eb2" },
                            }}
                        >
                            Close
                        </Button>
                    </Box>
                </Drawer>

                {/* Therapy Graph Section */}
                {graphData && (
                    <Box
                        sx={{
                            marginTop: "20px",
                            padding: "20px",
                            borderRadius: "8px",
                            backgroundColor: "#fff",
                            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                        }}
                    >

                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                backgroundColor: "#f8f9fa",
                                borderRadius: "8px",
                            }}
                        >
                            <MultiSubplotChart setLoadingAutoPdf={setLoadingAutoPdf} data={graphData} startDate={dateRange.from} endDate={dateRange.to} hidePreview={hidePreview} ref={childRef} averageValues={averageValues} serialNumber={serialNumber} />
                        </Box>
                    </Box>
                )}
            </Box>
        </>
    );
};

export default ReportPage;
