import axios from "axios";
import {
  CONFIRM_SIGNUP_OTP,
  GET_INVITATION,
  ADD_INVITATION_ITEM,
  GET_CLINIC,
  ADD_CLINIC_LIST,
  SIGN_IN,
  CHECKVALIDUSER,
  CHECKVALIDUSEREMAIL,
  CONFIRM_OTP,
  FORGOT_PASSWORD_CONFIRM_OTP,
  UPDATE_CLINIC_LIST,
  GET_USER_CLINIC_DETAILS,
  UPDATE_EXSITING_USER,
  VERIFY_USER_PHONE_NUMBER,
  RESEND_OTP_NEWUSER,
  CONFIRM_NEWUSER_PHONENUMBER_OTP,
  UPDATE_PROFILE_DATA,
  UPDATE_PASSWORD,
  UPDATE_PROFILE_PIC,
  USER_LOGIN_DATA,
  CHECK_EMAIL,
  ACCEPTED_SHARING,
  // CANCEL_SHARING_REQ,
  REVOKE_SHARING_REQ,
  ACCEPT_SHARING_REQ,
  SEND_SHARING_REQ,
  SIGNUP,
  FETCH_USER_PROFILE_DETAILS,
  USER_PROFILE_UPLOAD,
  CLINIC_PROFILE_PICTURE_UPDATE,
  ADD_NOTIFICATION_SETTING,
  GET_NOTIFICATION,
  EXITING_ACCOUNT_NUMBER,
  LOAD_PROFILE,
  PATIENT_REPORT_SEARCH,
  HELP_CENTER_API,
  UPDATE_DEVICE_SETTINGS,
  LOAD_DEVICE_SETTINGS,
  GET_PROFILE_QUICKID,
  ADD_NOTE,
  FETCH_NOTE,
  RESEND_INVITATION,
  ACTIVE_CARE_SITE_ADMIN,
  INVITATION_SEARCH,
  ACTIVE_CLINIC_SITE,
  ROUTES_ADHERANCE_SCORE_DATERANGE,
  ROUTES_ADHERANCE_SCORE_GRAPH,
  ROUTES_ADHERANCE_SCORE_MAP,
  DASHBOARD,
  LINK_VERIFICATION,
  REVOKE_USER,
  REVOKE_USERS,
  TOTAL_TRANSMITTED,
  WITH_DEVIATION,
  WITHOUT_DEVIATION,
  NEVER_TRANSMITTED,
  ADS_U75,
  ADS_U50,
  ADS_U25,
  USER_PENDING_REQ,
  ROUTES_HMR_GRAPH_DATERANGE,
  ROUTES_HMR_GRAPH,
  PASSWORD_VERIFICATION,
  NEW_EMAIL,
  CONFIRM_EMAIL_OTP,
  CONFIRM_FORGOTEMAIL_OTP,
  NEW_PHONE,
  CONFIRM_PHONE_OTP,
  CHANGE_PASSWORD,
  NOTIFICATION_SUMMARY_TABLE,
  ACCEPT_INVITATION,
  REJECT_INVITATION,
  INVITATION_PAGE_LIST,
  CHECK_IF_DEVICE_IS_CONNECTED,
  INACTIVE_PATIENT,
  GET_PATIENT_STATUS,
  NOTIFICATION_SUMMARY_PATIENT_REPORT,
  GET_PROFILE_FOR_UPDATE,
  ENROLLED_COUNT,
  ACTIVE_PATIENT_LIST,
  SEARCH_ON_CLINIC,
  SEARCH_ON_CLINICIAN,
  SEARCH_FOR_ABMADMINDASH,
  SEARCH_ON_PATIENT,
  FETCH_CLINIC,
  ACTIVE_CLINICADMIN_LIST,
  ACTIVE_CLINICIAN_LIST,
  INACTIVE_PATIENT_LIST,
  ALL_PATIENT_LIST,
  PATIENT_PROFILE_THERAPY_DETAILS_GRAPH,
  DOWNLOAD_PATIENT_ADHERE_SCORE_HISTORY,
  GET_CARESITE_LIST_FOR_INVITATION,
  SUPERADMIN_DASHBOARD_ENROLLED_LIST_COUNT,
  SUPERADMIN_DASHBOARD_CARESITE_LIST,
  EDIT_PATIENT_ID,
  CARE_SITE_DETAILS,
  GET_SENT_RECEIVED_INVITATION_COUNT,
  RESEND_OTP_LOGIN,
  RESEND_OTP_FORGOT,
  RESEND_OTP_FORGOTEMAIL,
  RESEND_OTP_REGISTER,
  UPLOAD_CSV_ENCRYPTED_FILE,
  GEN_THERAPY_TABLE_FROM_CSV,
  CHECK_USERNAME,
  MEASURING_UNITS,
  REPORT_SEARCH_USER,
  UPDATE_THERAPY_GOALS,
  RESEND_REJECTED_INVITATION,
  MISSED_THERAPY,
  FETCH_DEVICE_STATS_ROUTE,
} from "./routs";
let instance = axios.create({
  // baseURL: "http://localhost:1337",
  baseURL: "https://data1.arc.abmrc.com",
  // baseURL: "http://13.232.7.14:5000",
  // baseURL: "https://devicedatadiewer.herokuapp.com/",
  // baseURL: "https://arc-portal-be-staging.herokuapp.com"
});
//

export {
  GET_INVITATION,
  GET_CLINIC,
  ADD_CLINIC_LIST,
  SIGN_IN,
  CHECKVALIDUSER,
  CHECKVALIDUSEREMAIL,
  NOTIFICATION_SUMMARY_TABLE,
  CONFIRM_OTP,
  FORGOT_PASSWORD_CONFIRM_OTP,
  UPDATE_PROFILE_DATA,
  UPDATE_PASSWORD,
  UPDATE_PROFILE_PIC,
  UPDATE_CLINIC_LIST,
  GET_USER_CLINIC_DETAILS,
  ADD_INVITATION_ITEM,
  UPDATE_EXSITING_USER,
  VERIFY_USER_PHONE_NUMBER,
  RESEND_OTP_NEWUSER,
  CONFIRM_NEWUSER_PHONENUMBER_OTP,
  USER_LOGIN_DATA,
  CHECK_EMAIL,
  ACCEPTED_SHARING,
  // CANCEL_SHARING_REQ,
  REVOKE_SHARING_REQ,
  ACCEPT_SHARING_REQ,
  SEND_SHARING_REQ,
  SIGNUP,
  FETCH_USER_PROFILE_DETAILS,
  USER_PROFILE_UPLOAD,
  CLINIC_PROFILE_PICTURE_UPDATE,
  ADD_NOTIFICATION_SETTING,
  GET_NOTIFICATION,
  CONFIRM_SIGNUP_OTP,
  PATIENT_REPORT_SEARCH,
  HELP_CENTER_API,
  EXITING_ACCOUNT_NUMBER,
  LOAD_PROFILE,
  UPDATE_DEVICE_SETTINGS,
  LOAD_DEVICE_SETTINGS,
  GET_PROFILE_QUICKID,
  ADD_NOTE,
  FETCH_NOTE,
  RESEND_INVITATION,
  ACTIVE_CARE_SITE_ADMIN,
  INVITATION_SEARCH,
  ACTIVE_CLINIC_SITE,
  ROUTES_ADHERANCE_SCORE_DATERANGE,
  ROUTES_ADHERANCE_SCORE_GRAPH,
  ROUTES_ADHERANCE_SCORE_MAP,
  DASHBOARD,
  LINK_VERIFICATION,
  REVOKE_USER,
  REVOKE_USERS,
  TOTAL_TRANSMITTED,
  WITH_DEVIATION,
  WITHOUT_DEVIATION,
  NEVER_TRANSMITTED,
  ADS_U75,
  ADS_U50,
  ADS_U25,
  USER_PENDING_REQ,
  ROUTES_HMR_GRAPH,
  ROUTES_HMR_GRAPH_DATERANGE,
  PASSWORD_VERIFICATION,
  NEW_EMAIL,
  CONFIRM_EMAIL_OTP,
  CONFIRM_FORGOTEMAIL_OTP,
  NEW_PHONE,
  CONFIRM_PHONE_OTP,
  CHANGE_PASSWORD,
  ACCEPT_INVITATION,
  REJECT_INVITATION,
  INVITATION_PAGE_LIST,
  CHECK_IF_DEVICE_IS_CONNECTED,
  INACTIVE_PATIENT,
  GET_PATIENT_STATUS,
  NOTIFICATION_SUMMARY_PATIENT_REPORT,
  GET_PROFILE_FOR_UPDATE,
  ENROLLED_COUNT,
  ACTIVE_PATIENT_LIST,
  SEARCH_ON_CLINIC,
  SEARCH_ON_CLINICIAN,
  SEARCH_FOR_ABMADMINDASH,
  SEARCH_ON_PATIENT,
  FETCH_CLINIC,
  ACTIVE_CLINICADMIN_LIST,
  ACTIVE_CLINICIAN_LIST,
  INACTIVE_PATIENT_LIST,
  ALL_PATIENT_LIST,
  PATIENT_PROFILE_THERAPY_DETAILS_GRAPH,
  DOWNLOAD_PATIENT_ADHERE_SCORE_HISTORY,
  SUPERADMIN_DASHBOARD_ENROLLED_LIST_COUNT,
  SUPERADMIN_DASHBOARD_CARESITE_LIST,
  GET_CARESITE_LIST_FOR_INVITATION,
  EDIT_PATIENT_ID,
  CARE_SITE_DETAILS,
  GET_SENT_RECEIVED_INVITATION_COUNT,
  RESEND_OTP_LOGIN,
  RESEND_OTP_FORGOT,
  RESEND_OTP_FORGOTEMAIL,
  RESEND_OTP_REGISTER,
  UPLOAD_CSV_ENCRYPTED_FILE,
  GEN_THERAPY_TABLE_FROM_CSV,
  CHECK_USERNAME,
  MEASURING_UNITS,
  REPORT_SEARCH_USER,
  UPDATE_THERAPY_GOALS,
  RESEND_REJECTED_INVITATION,
  MISSED_THERAPY,
  FETCH_DEVICE_STATS_ROUTE,
};
instance.interceptors.request.use(
  async (config) => {
    // call every single time we make request through this instanceree
    const token = await localStorage.getItem("authtoken");
    config.headers["Access-Control-Allow-Origin"] = "*";
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (network_error) => {
    return Promise.reject(network_error);
  }
);
export default instance;
