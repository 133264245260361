/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import {
  logIn,
  signIn,
  confirmOTP,
  userLoginData,
  userPendingData,
  resetLoginErrorMsg,
  resend_otp_login,
  makeOtpResendLoginEmpty
} from "../../../redux/user/user.action";

import {
  loginUser,
  updateInvitationItem,
  AlertMsg,
} from "../../../redux/invitation/components/invitation.action";

// import { makeStyles } from "@material-ui/core/styles";
import InputField from "../../../components/Inputs_Validation/index";
import "./styles.css";
import welcome from "../../../assets/img/welcome.png";
import OtpInput from "react-otp-input";
import FooterSign from "../../../components/Footers/FooterSign";
import {
  superAdmin,
  abmAdmin,
  clinicadmin,
  clinician,

} from "../../../constant/constantRole";
import '../../../assets/styles/font.css';
import CustomSnackbar from "../../../components/Alertmessage/AlertMsg";
import LodingIcon from "../../../components/LoadingIcon";
import Box from '@mui/material/Box';
import UploadCSV from "../../../components/Report/UploadCSV";
// const useStyles = makeStyles((theme) => ({
//   root: {
//     width: "100%",
//     "& > * + *": {
//       marginTop: theme.spacing(2),
//     },
//   },
// }));
const Login = (props) => {

  const location = useLocation();
  // const classes = useStyles();
  const [otp, setopt] = useState("");
  const [values, setValues] = React.useState({ username_email: location?.state?.email });
  const [openuploadcsv, setopenuploadcsv] = React.useState(false);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [snackSeverity, setSnackSeverity] = React.useState('error');
  const [snackMsg, setSnackmsg] = React.useState('');
  const [anchororigin, setanchorOrigin] = useState({
    vertical: 'top',
    horizontal: 'center',
  });
  var history = useHistory();
  // const location = useLocation()
  // const [emailfromregister,setemailregister]  = useState('')
  const inputRefs = React.useRef([React.createRef(), React.createRef()]);
  const [counter, setCounter] = React.useState(300);
  const otpRef = React.createRef()
  const [resendOtpConditaion, setresendotpCondition] = React.useState("resendotp");
  const [clickNextButton, SetClickNextButton] = React.useState(false);
  const getMaskedNumber = (phone_number) => {
    if (phone_number) {
      let digits = phone_number.slice(phone_number.length - 3);
      let phone_num = 'x'.repeat(phone_number.length - 3);
      phone_num = phone_num + digits
      return phone_num;
    }
    return '';
  }
  const handleInputChange = (name, value) => {
    if (value.length > 0) {
      setValues({
        ...values,
        [name]: value,
      });
    }
  };
  function openuploadcsvpopup() {
    setopenuploadcsv(true);
  }
  function closeUploadCsvPopup() {
    setopenuploadcsv(false);
  }
  //  console.log('location',emailfromregister )
  // useEffect(()=>{
  //   if(location && location.state)
  //   setemailregister(location.state.emailfromregister )
  // },[location])
  const submit = async () => {

    let isValid = true;
    for (let i = 0; i < inputRefs.current.length; i++) {
      if (inputRefs.current[i].current === null) {
        continue;
      }
      const valid = inputRefs.current[i].current.validate();
      if (!valid) {
        isValid = false;
      }
    }
    if (isValid === true) {
      SetClickNextButton(true);
      props.signIn(values);
      /*   SetClickNextButton(false); */
    }
  };

  useEffect(() => {
    if (resendOtpConditaion === "resendotp") {
      setresendotpCondition("mailmsg");
    }

    if (props.otpSent === true) {
      SetClickNextButton(false);
      counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
      setresendotpCondition("otpexpire")
    }
    if (counter === 0) {
      setresendotpCondition("resendotp");
    }
    return () => props.makeOtpResendLoginEmpty();

  }, [counter, props.otpSent]);


  const resendCode = () => {
    props.makeOtpResendLoginEmpty();
    setresendotpCondition("mailmsg");
    props.resend_otp_login(values);
    otpRef.current.focusInput(0);
  }
  function timer1(counter) {
    let minutes = Math.floor(counter / 60);
    let sec = counter % 60;
    let seconds = sec

    if (Math.floor((sec / 10)) === 0) { seconds = '0' + sec }

    return `${minutes}:${seconds}`

  }


  useEffect(() => {
    if (props.resendotpcodelogin === "resendOtplogin" && props.otpSent === true) {
      setCounter(300);
      counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
      setresendotpCondition("otpexpire")
    }
  }, [props.resendotpcodelogin, props.otpSent])



  const submitOTP = () => {
    SetClickNextButton(true);
    if (
      otp.length === 4 &&
      props.otpToken /* && otp === props.loginOtp */
    ) {
      let code = otp;
      props.confirmOTP({ code, token: props.otpToken });
      setOpenSnack(false);
    } else {
      SetClickNextButton(false);
      setSnackmsg("Incorrect code! Please try again")
      setOpenSnack(true);
    }
  };


  useEffect(() => {
    if (props.isMatch && props.user) {
      SetClickNextButton(false);
      let role = props.user.role;
      let userRole =
        role === superAdmin
          ? "SUPER_ADMIN"
          : role === abmAdmin
            ? "ADMIN"
            : role === clinicadmin
              ? "CLINIC_ADMIN"
              : role === clinician
                ? "CLINICIAN"
                : "PATIENT";
      if (userRole) {
        props.userLoginData(props.user.id);
        localStorage.setItem("user", JSON.stringify(props.user));
        localStorage.setItem("roles", JSON.stringify(userRole));
        if (role === "clinicadmin" && localStorage.getItem("isAssociatedClinic") === 0) {
          history.push("/app/clinicadmin");
        } else if (role === "clinician" && localStorage.getItem("isAssociatedClinic") === 0) {
          history.push("/app/cliniciantable")
        } else {
          history.push("/app");
        }
      }
    }
  }, [props.isMatch])

  const handleChange = (otp) => {
    setopt(otp);
  };

  const handleSnackbar = () => {
    setOpenSnack(false);
    props.resetLoginErrorMsg();
  };


  useEffect(() => {
    if (props.loginErrorMsg) {
      setOpenSnack(true);
      setSnackmsg(props.loginErrorMsg);
      SetClickNextButton(false);
    } else if (props.otpexpireerror === 404) {
      setOpenSnack(true);
      setSnackmsg("Verification code is expired! Please try again with new one");
      SetClickNextButton(false);
    }
    else if (props.otpexpireerror === 400) {
      setOpenSnack(true);
      setSnackmsg("Incorrect Code. Please check your code and try again.")
    } else {
      setOpenSnack(false);
      props.resetLoginErrorMsg();
    }

  }, [props.loginErrorMsg, props.otpexpireerror])


  return (
    <>
      <CustomSnackbar
        flag={openSnack}
        onClose={handleSnackbar}
        anchorOrigin={anchororigin}
        severity={snackSeverity}
        msg={snackMsg}
      />
      <UploadCSV isopen={openuploadcsv} closePopup={closeUploadCsvPopup} />
      <div className="wrapperContainer">

        <div className="lg:flex">
          <div className="flex-1 leftSide">
            <div className="imgWrapper">
              <img src={welcome} className="image" alt="" />
            </div>
            <div className="LoginLeftContentWrapper py-16">
              Get Connected. Stay Informed. <br /> Collaborate.
            </div>
          </div>
          <div className="flex-1 rightSide">
            <div className="text_1">
              <div className="innerText">
                Welcome

                <div className="innerText2">
                  <span style={{ color: "#6a768f" }}>to</span> Arc Connect
                </div>
              </div>
            </div>


            <div className="inputFieldHeightWidthAdjustment11">
              <div className="flex flex-wrap">
                <div className="w-full lg:w-full">
                  <div className="signInEmailAddress fnt24P" >
                    Username / Email address


                  </div>
                  <div>
                    <InputField
                      type="email"
                      className="emailInputLogin fnt24P"
                      placeholder=""
                      disabled={props.otpSent}
                      defaultValue={location?.state?.email}
                      ref={inputRefs.current[0]}
                      handleInputChange={handleInputChange}
                      validation="required"
                      name="username_email"
                    />

                  </div>

                </div>

              </div>

              <div className="flex flex-wrap mt-4">
                <div className="w-full lg : w-full">
                  <div className="signInEmailPwd fnt24P " >

                    Password



                  </div>


                  <div>
                    <InputField
                      type={"password"}
                      className="pwdInputLogin fnt24P"
                      ref={inputRefs.current[1]}
                      handleInputChange={handleInputChange}
                      placeholder=""
                      validation="required|min:6"
                      name="password"
                      disabled={props.otpSent}
                      isPassword={true}
                    />

                  </div>

                </div>
              </div>
            </div>

            {props.otpSent === true ? (
              <div>

                <div className="otpMsg fnt21P">
                  You will receive a verification code to your mobile number {getMaskedNumber(props.phone)}
                </div>
                {/* <div className="otpMsg fnt21P">OTP:- <span style={{ color: '#F26930' }}> {props.loginOtp} </span> </div> */}
                <div className="labels">
                  <label className="labaleforSingnIn fnt21P">Enter Verification Code</label>
                </div>

                <div className="InputOpt">
                  <OtpInput
                    value={otp}
                    ref={otpRef}
                    type="password"
                    onChange={handleChange}
                    numInputs={4}
                    shouldAutoFocus={true}
                    inputStyle={{
                      width: "80px",
                      fontSize: "18px",
                      padding: "21px",
                      marginLeft: "7px",
                      borderRadius: "13px",
                      boxShadow: "#6A768F80 0px 3px 6px",
                      border: "none",
                    }}

                    separator={<span></span>}
                  />
                </div>
                <div className="otp-time">
                  {
                    resendOtpConditaion === "resendotp" ? <p id="resnedotp">Your earlier Verification Code has been expired ! <span>
                      <a style={{ color: "#f26930", cursor: "pointer" }} onClick={() => resendCode()}>Resend Verification code</a></span></p> : null
                  }
                  {
                    resendOtpConditaion === "otpexpire" ? <p id="otpexpire">Your Verification Code Expire in : <span>{timer1(counter)} sec</span> </p> : null
                  }

                  {resendOtpConditaion === "mailmsg" ? <p style={{ color: "green" }}>New Verification Code has been sent to your registered mobile!</p> : null}
                </div>
                <div className="nextButtonnnnOuter">
                  <div
                    className="nextButtonnnn fnt32P  next w-full lg : w-full"
                    type="button"
                    onClick={() =>
                      props.otpSent === true ? submitOTP() : submit()
                    }
                  >
                    {clickNextButton ? <Box sx={{
                      display: 'flex', width: "40px", height: "29px", marginTop: "-5px"
                    }}>  <LodingIcon /></Box> : "Submit"}
                  </div>
                </div>
              </div>
            ) : (
              <div>
                {
                  <div className="nextButtonnnnOuter flex flex-wrap nextbutn">
                    <div
                      className="nextButtonnnn fnt32P  next w-full lg : w-full"
                      type="button"
                      onClick={() =>
                        props.otpSent === true ? submitOTP() : submit()
                      }
                    >
                      {/*   Next */}
                      {clickNextButton ? <Box sx={{
                        display: 'flex', width: "40px", height: "29px", marginTop: "-5px"
                      }}>  <LodingIcon /></Box> : "Next"}
                    </div>
                    {/* </div> */}
                  </div>
                }
                <div className="centeredLink" >
                  <a variant="contained" href="/forgotpassword" >
                    Forgot your password?
                  </a>
                </div>
                <div className="account fnt32P">

                  {/* <span
                    style={{
                      color: "#F26930",
                      fontWeight: "500",
                      borderBottom: "2px solid #F26930",
                    }}
                  >
                    <Link
                      className={window.location.href.indexOf("/signup")}
                      to="/signup"
                    >
                      Sign Up
                    </Link>{" "}
                  </span> */}
                </div>
              </div>
            )}
            <div className="centeredButton" style={{ marginTop: '150px', marginBottom: '50px', display: 'flex', flexWrap: "wrap", alignItems: 'center', gap: '30px', display: `${props.otpSent === true ? 'none' : ''}` }}>
              <button className="uploadcsvButton2"
                onClick={() => {
                  openuploadcsvpopup();
                }}
              >
                Decrypt Log Files
              </button>
              <div>Use the decryption tool for the BiWaze® Cough <br></br>system log files.</div>
            </div>
          </div>
        </div>
      </div>
      <div className="">
        <FooterSign />
      </div>
    </>
  );
};
const mapStateToProps = (store) => {
  return {
    msg: store.invitation.msg,
    otpSent: store.user.otpSent,
    otpToken: store.user.otpToken,
    user: store.user.user,
    isMatch: store.user.isMatch,
    loginOtp: store.user.loginOtp,
    loginErrorMsg: store.user.loginErrorMsg,
    otpexpireerror: store.user.otpexpireerror,
    resendotpcodelogin: store.user.resendotpcodelogin,
    phone: store.user.phone
  };
};
export default connect(mapStateToProps, {
  logIn,
  loginUser,
  updateInvitationItem,
  AlertMsg,
  signIn,
  confirmOTP,
  userLoginData,
  userPendingData,
  resetLoginErrorMsg,
  resend_otp_login,
  makeOtpResendLoginEmpty
})(Login);


