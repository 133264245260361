import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import {
    Home as OverviewIcon,
    Assessment as ReportsIcon,
    Group as PatientsIcon,
    MedicalServices as DiagnoseIcon,
} from "@mui/icons-material";

const NavBar = ({ activeTab, setActiveTab }) => {

    const navItems = [
        { label: "Overview", icon: <OverviewIcon /> },
        { label: "Medical Reports", icon: <ReportsIcon /> },
        { label: "Patients Overview", icon: <PatientsIcon /> },
        { label: "Diagnose", icon: <DiagnoseIcon /> },
    ];

    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "#fff",
                padding: "10px 20px",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                borderBottom: "1px solid #f1f1f1",
            }}
        >
            {navItems.map((item) => (
                <Box
                    key={item.label}
                    onClick={() => setActiveTab(item.label)}
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                        marginRight: "30px",
                        color: activeTab === item.label ? "#1a73e8" : "#6c757d",
                        position: "relative",
                        fontSize: "14px",
                        "&:hover": {
                            color: "#1a73e8",
                        },
                    }}
                >
                    {item.icon}
                    <Typography
                        sx={{
                            marginLeft: "8px",
                            fontSize: "14px",
                            fontWeight: activeTab === item.label ? "bold" : "normal",
                        }}
                    >
                        {item.label}
                    </Typography>
                    {activeTab === item.label && (
                        <Box
                            sx={{
                                position: "absolute",
                                bottom: "-5px",
                                left: 0,
                                height: "2px",
                                width: "100%",
                                backgroundColor: "#1a73e8",
                                borderRadius: "2px",
                            }}
                        />
                    )}
                </Box>
            ))}
        </Box>
    );
};

export default NavBar;
