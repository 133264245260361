import React, { useEffect } from "react";
import CloseIcon from '@material-ui/icons/Close';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from '@mui/material/DialogContent';
import '../styles/inActivePopUp.css';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from "@material-ui/core/Button";
import apiAbmBlaze, { UPLOAD_CSV_ENCRYPTED_FILE } from '../../api';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
    InsertDriveFileOutlined,
    PauseCircleOutline,
    CancelOutlined, CheckCircleOutlined
} from "@mui/icons-material";
import { ShimmerProgressBar } from "./../../screens/PublicRoutes/Decrypt/progress-bar";

const UploadCSV = (props) => {
    const [filedata, setFiledata] = React.useState("");
    const [CsvResponse, setCSVResponse] = React.useState(false);
    const [uploadcsvHide, setUploadcsvHide] = React.useState(true);
    const [uploadingerorr, setUploadingerror] = React.useState(false);
    const [decryptedCsvData, setDecryptedCsvData] = React.useState('');
    const [rawDecryptFileData, setRawDecryptedFileData] = React.useState('');
    const [uuid, setUuid] = React.useState('');
    const [startfrom, setStartfrom] = React.useState(false);
    const [downloadpdfinprogress, setdownloadpdfinprogress] = React.useState(false);
    const hiddenFileInput = React.useRef(null);
    const [downloadpdferror, setdownloadpdferror] = React.useState(false);
    const [openSnack, setOpenSnack] = React.useState(false);
    const [snackMsg, setSnackMsg] = React.useState("");
    const [snackSeverity, setSnackSeverity] = React.useState("");
    const [customeSnackbarAnchor, setCustomeSnackbarAnchor] = React.useState({
        vertical: "top",
        horizontal: "center",
    });
    const history = useHistory()
    const [processing, setProcessing] = React.useState(false); // Processing state
    const [uploadProgress, setUploadProgress] = React.useState(0); // Upload progress
    const [timeRemaining, setTimeRemaining] = React.useState(20); // Time remaining (simulated)
    const [fileUploaded, setFileUploaded] = React.useState(false); // File upload completion state
    const [processStep, setProcessStep] = React.useState(0); // Process step index
    const [fileDetails, setFileDetails] = React.useState(null);


    // Resetting state so that, need not be refreshed to decrypt another file
    useEffect(() => {
        if (props.isopen) {
            setFiledata('');
            setCSVResponse(false);
            setUploadcsvHide(true);
            setUploadingerror(false);
            setdownloadpdfinprogress(false);
            setDecryptedCsvData('');
            setStartfrom(false);
        }
    }, [props.isopen])
    const handleFileCSV = (e) => {
        simulateUpload();
        const file = e.target.files?.[0];
        if (!file) return;

        setFiledata([file]);
        setUploadingerror(false);
        const fileName = file.name
        const [deviceId, date, time] = fileName.replace(/_system$/, "").split("_");
        if (!deviceId || !date || !time) {
            console.error("Invalid file name format");
            return;
        }

        setFileDetails({
            fileName,
            deviceId,
            deviceType: deviceId.startsWith("C") ? "Biwaze Cough" : "Biwaze Clear",
            date,
            time,
        });
    };


    useEffect(() => {
        if (!filedata) {
            return;
        }
        // setCSVResponse(true);
        // setUploadcsvHide(false);
        let GetCSVFile = filedata[0];
        let formData = new FormData();
        formData.append("uploaded-csv", GetCSVFile);

        apiAbmBlaze.post(UPLOAD_CSV_ENCRYPTED_FILE, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }).then((response) => {
            setCSVResponse(false);
            if (response && response.data) {
                if (!response.data.uuid) {
                    setRawDecryptedFileData(response.data)
                    setDecryptedCsvData(null)
                } else {
                    setDecryptedCsvData(response.data.csv);
                    setUuid(response.data.uuid)
                    setRawDecryptedFileData(null)
                }
            }
        }).catch((error) => {
            if (error) {
                setCSVResponse(false);
                setUploadingerror(true);
                setUploadcsvHide(true);
            }
        })
    }, [filedata])

    const DownloadCsv = (e) => {
        setStartfrom(true);
        if (decryptedCsvData || rawDecryptFileData) {
            const url = window.URL.createObjectURL(new Blob([decryptedCsvData ?? rawDecryptFileData], {}));
            const link = document.createElement('a');
            link.href = url;
            link.download = `Decrypted_${filedata[0].name}`;
            link.click();
            window.URL.revokeObjectURL(url);
            // setTimeout(() => closingInto(), 1000);
        }
    }
    const handleSnackbar = () => {
        setOpenSnack(false);
    };
    const closingInto = () => {
        props.closePopup();
        if (startfrom) {
            setUploadcsvHide(true);
        }
        setFileUploaded(false)
        setUploadProgress(0)
        setProcessing(false)
        setDecryptedCsvData(null)
        setRawDecryptedFileData(null)
    }
    const steps = [
        "🔍 Validating your data...",
        "🔒 Encrypted with top-tier security!",
        "🔓 Decrypting the data...",
        "📊 Extracting therapy insights...",
        "🛠️ Building your report...",
        "🎉 Final touches in progress!",
        "✨ Simplifying for easy use...",
        "📥 Ready! Download or view on the dashboard.",
    ];


    // Simulate file upload
    const simulateUpload = () => {
        setUploadProgress(0); // Reset progress
        setTimeRemaining(18); // Reset time
        setFileUploaded(false); // Reset file uploaded status

        const uploadStep = () => {
            if (uploadingerorr) {
                console.error("Upload error detected. Stopping simulation.");
                setFileUploaded(false);
                return; // Stop further execution if an error occurs
            }

            setUploadProgress((prev) => {
                const nextValue = prev + 5;

                if (nextValue >= 100) {
                    setFileUploaded(true); // Mark upload as complete
                    return 100; // Cap progress at 100
                }

                // Schedule the next step
                setTimeout(uploadStep, 500);
                return nextValue;
            });

            setTimeRemaining((prev) => (prev > 0 ? prev - 1 : 0));
        };

        // Start the upload simulation
        setTimeout(uploadStep, 500);
    };


    // Trigger file explorer
    const handleBrowseClick = () => {
        hiddenFileInput.current.click();
    };

    // Start processing steps
    const startProcessing = () => {
        setProcessing(true);
        let step = 0;

        const processInterval = setInterval(() => {
            if (!fileUploaded) return; // Prevent processing without file upload

            if (uuid) {
                setProcessStep(steps.length - 1);
            }
            else if (step < steps.length - 1) {
                setProcessStep((prev) => prev + 1);
                step++;
            } else {
                clearInterval(processInterval);
            }
        }, 1500);
    };

    // Cancel upload
    const cancelUpload = () => {
        setUploadProgress(0);
        setTimeRemaining(0);
        setFileUploaded(false);
        setProcessing(false);
        setFiledata("");
        setCSVResponse(false);
        setUploadcsvHide(true);
        setUploadingerror(false);
        setDecryptedCsvData("");
        setProcessing(false)
        closingInto()
    };
    return (
        <>

            <AlertSnackBar
                msg={snackMsg}
                flag={openSnack}
                onClose={handleSnackbar}
                key={"cupSnackbar"}
                anchorOrigin={customeSnackbarAnchor}
                severity={snackSeverity}
            />


            <div className="media-patient upload-csv">
                <Dialog
                    open={props.isopen}
                    onClose={closingInto}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title media-patient">
                        <div className="Ptient-heading-container">
                            <div className="patient-title">{/* Upload Section */}
                                <h5
                                    style={{
                                        fontFamily: "'Inter', sans-serif",
                                        fontWeight: "600",
                                        color: "#4a4a4a",
                                        marginBottom: "20px",
                                        padding: "6px",
                                    }}
                                >
                                    Upload Encrypted CSV
                                </h5></div>
                            <div className="iconForCancelptient">
                                <CloseIcon onClick={closingInto} style={{ cursor: "pointer" }} />
                            </div>
                        </div>
                    </DialogTitle>
                    {uploadcsvHide ? <DialogContent className="media-patient">
                        <DialogContentText id="alert-dialog-description">
                            <div style={{ height: '250px' }}>
                                <input
                                    type="file"
                                    ref={hiddenFileInput}
                                    onChange={handleFileCSV}
                                    style={{ display: "none" }}
                                    accept=".csv"
                                />
                                {!processing ? (
                                    <>

                                        {!uploadProgress && <div
                                            className="border border-dashed p-4 text-center"
                                            style={{
                                                borderColor: "#d1e5fc",
                                                borderRadius: "12px",
                                                backgroundColor: "#f9fcff",
                                                marginBottom: "20px",
                                                opacity: uploadProgress < 30 ? 1 : 0, // Fade effect
                                                transform: uploadProgress < 30 ? "scale(1)" : "scale(0.95)", // Slight shrink effect
                                                transition:
                                                    "opacity 0.3s ease, transform 0.3s ease", // Smooth transitions
                                            }}
                                        >
                                            <InsertDriveFileOutlined
                                                onClick={handleBrowseClick}
                                                style={{
                                                    fontSize: "48px",
                                                    color: "#2979ff",
                                                }}
                                            />
                                            <p
                                                style={{
                                                    fontSize: "12px",
                                                    color: "#2979ff",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                CSV
                                            </p>

                                            <p className="text-muted">
                                                Drop your encrypted CSV file here, or{" "}
                                                <span
                                                    onClick={handleBrowseClick}
                                                    style={{
                                                        color: "#2979ff",
                                                        fontWeight: "600",
                                                        cursor: "pointer",
                                                    }}
                                                >
                                                    browse
                                                </span>
                                            </p>
                                        </div>}

                                        {uploadProgress > 0 && (
                                            <>
                                                <div>
                                                    {fileDetails && (
                                                        <>
                                                            <p style={{ textAlign: "center", fontWeight: "bold", margin: "10px 0" }}>
                                                                Thanks for using {fileDetails.deviceType}
                                                            </p>
                                                            <div

                                                            >
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        justifyContent: "space-between",
                                                                        alignItems: "center",
                                                                        padding: "10px 15px",
                                                                        background: "#f9f9f9",
                                                                        borderRadius: "8px",
                                                                        boxShadow: "0 1px 4px rgba(0, 0, 0, 0.1)",
                                                                        fontSize: "12px",
                                                                        lineHeight: "1.5",
                                                                        maxWidth: "600px",
                                                                        margin: "10px auto",
                                                                    }}
                                                                >
                                                                    <span>Serial: <span style={{ color: "#1e88e5" }}>{fileDetails.deviceId}</span></span>
                                                                    <span>Date: <span style={{ color: "#1e88e5" }}>
                                                                        {`${fileDetails.date.slice(0, 2)}-${fileDetails.date.slice(2, 4)}-${fileDetails.date.slice(4)}`}
                                                                    </span></span>
                                                                    <span>Time: <span style={{ color: "#1e88e5" }}>
                                                                        {`${fileDetails.time.slice(0, 2)}:${fileDetails.time.slice(2, 4)}:${fileDetails.time.slice(4)}`}
                                                                    </span></span>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}



                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                            alignItems: "center",
                                                            margin: "0 20px",
                                                            padding: "5px",
                                                        }}
                                                    >
                                                        <div  >
                                                            <p
                                                                style={{
                                                                    fontFamily: "'Inter', sans-serif",
                                                                    fontSize: "14px",
                                                                    fontWeight: "600",
                                                                    color: "#4a4a4a",
                                                                    padding: "2px",
                                                                }}
                                                            >


                                                                {uploadProgress < 100 && !uploadingerorr
                                                                    ? `${fileDetails.fileName}... `
                                                                    : uploadingerorr ? "Error File Contents" : "Click Proceed to Continue"}
                                                            </p>
                                                            <p
                                                                style={{
                                                                    fontFamily: "'Inter', sans-serif",
                                                                    fontSize: "12px",
                                                                    fontWeight: "400",
                                                                    color: "#9ba9c5",
                                                                    margin: 0,
                                                                }}
                                                            >
                                                                {uploadProgress}% • {timeRemaining} seconds left
                                                            </p>
                                                        </div>
                                                        <div style={{ display: "flex", gap: "10px" }}>
                                                            <button
                                                                className="btn btn-icon"
                                                                style={{
                                                                    border: "none",
                                                                    backgroundColor:
                                                                        uploadProgress > 70
                                                                            ? uploadProgress < 100
                                                                                ? "#e6e6e6"
                                                                                : "#c0c0c0"
                                                                            : "#f0f4fd", // Dynamic color
                                                                    borderRadius: "50%",
                                                                    padding: "10px",
                                                                    cursor: uploadProgress > 70 ? "not-allowed" : "pointer", // Change cursor
                                                                    visibility:
                                                                        uploadProgress > 10 ? "hidden" : "visible", // Make invisible after 10%
                                                                    transition:
                                                                        "visibility 0.3s ease, opacity 0.3s ease", // Smooth transition
                                                                    opacity: uploadProgress > 10 ? 0 : 1, // Fade out
                                                                }}
                                                                disabled={uploadProgress > 70}
                                                            >
                                                                <PauseCircleOutline
                                                                    style={{ fontSize: "20px", color: "#9ba9c5" }}
                                                                />
                                                            </button>

                                                            {/* <input
                                                                type="file"
                                                                ref={hiddenFileInput}
                                                                onChange={handleFileCSV}
                                                                style={{ display: "none" }}
                                                                accept=".csv"
                                                            /> */}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <ShimmerProgressBar uploadProgress={uploadProgress} />
                                                    <div>
                                                        <button
                                                            className="btn btn-icon"
                                                            onClick={cancelUpload}
                                                            style={{
                                                                border: "none",
                                                                background: "none", // Remove background for a minimal look
                                                                borderRadius: "50%",
                                                                transition:
                                                                    "visibility 0.3s ease, opacity 0.3s ease, cursor 0.3s ease", // Smooth animation for hover/disable
                                                            }}
                                                        // disabled={uploadProgress > 70}
                                                        >
                                                            <CancelOutlined
                                                                style={{ fontSize: "22px", color: "#738490" }}
                                                            />
                                                        </button>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "flex-end", // Align content to the right
                                                marginTop: "20px", // Add some spacing if needed
                                            }}
                                        >        {rawDecryptFileData ?
                                            <button
                                                onClick={DownloadCsv}
                                                style={{
                                                    border: "1px solid #d1e5fc", // Subtle border
                                                    backgroundColor: fileUploaded
                                                        ? "#f4f9ff"
                                                        : "rgb(237 238 240)", // Softer background
                                                    borderRadius: "8px", // Slightly rounded corners
                                                    padding: "12px 16px", // Adjusted padding for a balanced look
                                                    color: !fileUploaded ? "#a2b6d6" : "#2979ff", // Modern blue color for text
                                                    fontWeight: "600", // Bold font for better visibility
                                                    fontFamily: "'Inter', sans-serif", // Clean font style
                                                    display: "flex", // Align icon and text
                                                    alignItems: "center", // Vertical alignment
                                                    gap: "8px", // Space between icon and text
                                                    cursor: "pointer", // Pointer for interactivity
                                                    transition: "all 0.3s ease", // Smooth transition for hover
                                                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Soft shadow for depth
                                                }}
                                            >
                                                Download CSV
                                            </button> : <button
                                                onClick={startProcessing}
                                                disabled={!fileUploaded || uploadingerorr}
                                                style={{
                                                    border: "1px solid #d1e5fc",
                                                    backgroundColor: !fileUploaded || uploadingerorr
                                                        ? "rgb(237, 238, 240)"
                                                        : "#f4f9ff",
                                                    borderRadius: "8px",
                                                    padding: "12px 16px",
                                                    color: !fileUploaded || uploadingerorr
                                                        ? "#a2b6d6"
                                                        : "#2979ff",
                                                    fontWeight: "600",
                                                    fontFamily: "'Inter', sans-serif",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: "8px",
                                                    cursor: !fileUploaded || uploadingerorr
                                                        ? "not-allowed"
                                                        : "pointer",
                                                    transition: "all 0.3s ease",
                                                    boxShadow: !fileUploaded || uploadingerorr
                                                        ? "none"
                                                        : "0 4px 6px rgba(0, 0, 0, 0.1)",
                                                }}
                                            >
                                                Proceed
                                            </button>}


                                        </div>
                                    </>
                                ) : (
                                    <>
                                        {/* Processing Section */}
                                        <div className="text-center justify-center align-center" style={{ "margin": "auto" }}>


                                            <div className="text-center justify-center align-center">
                                                {(!decryptedCsvData) && (
                                                    <CircularProgress
                                                        style={{
                                                            color: "#2979ff",
                                                            margin: "20px",
                                                        }}
                                                    />
                                                )}

                                                {decryptedCsvData && (
                                                    <CheckCircleOutlined
                                                        style={{
                                                            color: "#4caf50", // Green color for success
                                                            margin: "20px",
                                                            fontSize: "46px", // Adjust size
                                                            animation: "bounce 1s", // Add animation
                                                        }}
                                                    />
                                                )}
                                            </div>

                                            <p
                                                style={{
                                                    fontFamily: "'Inter', sans-serif",
                                                    fontSize: "14px",
                                                    fontWeight: "500",
                                                    color: "#4a4a4a",
                                                }}
                                            >
                                                {steps[processStep]}
                                            </p>

                                            {decryptedCsvData && (
                                                <div>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "center", // Align content to the right
                                                            marginTop: "20px", // Add some spacing if needed       
                                                            gap: "20px", // Add space between the buttons

                                                        }}
                                                    >
                                                        <button
                                                            onClick={DownloadCsv}
                                                            style={{
                                                                border: "1px solid #d1e5fc", // Subtle border
                                                                backgroundColor: fileUploaded
                                                                    ? "#f4f9ff"
                                                                    : "rgb(237 238 240)", // Softer background
                                                                borderRadius: "8px", // Slightly rounded corners
                                                                padding: "12px 16px", // Adjusted padding for a balanced look
                                                                color: !fileUploaded ? "#a2b6d6" : "#2979ff", // Modern blue color for text
                                                                fontWeight: "600", // Bold font for better visibility
                                                                fontFamily: "'Inter', sans-serif", // Clean font style
                                                                display: "flex", // Align icon and text
                                                                alignItems: "center", // Vertical alignment
                                                                gap: "8px", // Space between icon and text
                                                                cursor: "pointer", // Pointer for interactivity
                                                                transition: "all 0.3s ease", // Smooth transition for hover
                                                                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Soft shadow for depth
                                                            }}
                                                        >
                                                            Download CSV
                                                        </button>
                                                        {fileDetails.deviceType === 'Biwaze Cough' ? <button
                                                            onClick={() => { history.push(`/decrypt?uuid=${uuid}&id=${fileDetails.deviceId}`) }}
                                                            disabled={!fileUploaded}
                                                            style={{
                                                                border: "1px solid #d1e5fc", // Subtle border
                                                                backgroundColor: fileUploaded
                                                                    ? "#f4f9ff"
                                                                    : "rgb(237 238 240)", // Softer background
                                                                borderRadius: "8px", // Slightly rounded corners
                                                                padding: "12px 16px", // Adjusted padding for a balanced look
                                                                color: !fileUploaded ? "#a2b6d6" : "#2979ff", // Modern blue color for text
                                                                fontWeight: "600", // Bold font for better visibility
                                                                fontFamily: "'Inter', sans-serif", // Clean font style
                                                                display: "flex", // Align icon and text
                                                                alignItems: "center", // Vertical alignment
                                                                gap: "8px", // Space between icon and text
                                                                cursor: "pointer", // Pointer for interactivity
                                                                transition: "all 0.3s ease", // Smooth transition for hover
                                                                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Soft shadow for depth
                                                            }}
                                                        >
                                                            Proceed to Dash Board
                                                        </button> : ""}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </>
                                )}
                            </div>
                        </DialogContentText>
                        {uploadingerorr ? <span style={{ color: "red", fontWeight: "400", textAlign: "center", display: "block", marginTop: "10px" }}>Something Went Wrong, Please Try Again!</span> : null}
                    </DialogContent> : CsvResponse && !uploadcsvHide ? <></> : <div className="media-patient" style={{ height: "280px" }}>
                        <p style={{ textAlign: "center", marginBottom: "10px", fontFamily: "Roboto", fontWeight: 500, color: "#646a8d", fontSize: "14px", marginTop: "50px", fontSize: '20px' }}>Select Download to save the decrypted file in a csv format.<br />
                        </p>
                        <div class="upload-btn-wrapper" style={{ display: "flex", justifyContent: "space-around", alignItems: "center", marginTop: "50px" }}>
                            <button class="btn2" onClick={(e) => DownloadCsv(e)}><i class="fa fa-download" aria-hidden="true"></i> &nbsp;&nbsp;Download CSV</button>
                            <input type="button" name="myfile" />

                        </div>
                    </div>}
                </Dialog>
            </div >
        </>
    )
}
export default UploadCSV;
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export const AlertSnackBar = ({
    flag,
    onClose,
    anchorOrigin,
    msg,
    severity,
}) => {
    return (
        <Snackbar
            anchorOrigin={
                anchorOrigin ? anchorOrigin : { vertical: "top", horizontal: "right" }
            }
            open={flag}
            autoHideDuration={5000}
            onClose={onClose}
        >
            <Alert onClose={onClose} severity={severity ? severity : "success"}>
                {msg}
            </Alert>
        </Snackbar>
    );
};