export const ADD_CLINIC_LIST = "/api/addClinic";
//Sigin Route
export const SIGN_IN = "/api/login";
export const CONFIRM_OTP = "/api/confirmotp";
export const CONFIRM_SIGNUP_OTP = "/api/signupconfirmotp";

export const CONFIRM_OTP_DUP = "/api/confirmotp";
//forgot password route
export const CHECKVALIDUSER = "/api/checkvaliduser";
export const CHECKVALIDUSEREMAIL = "/api/checkvaliduseremail";

export const FORGOT_PASSWORD_CONFIRM_OTP = "/api/forgotpasswordconfirmotp";

//Invitation Route
export const GET_INVITATION = "/api/invitation";
//Getting clinic route
export const GET_CLINIC = "/api/cliniclist";
//profile update ------------------------------------------------------1
export const UPDATE_PROFILE_DATA = "/api/UserprofileUpdate";
// pofilepic
export const UPDATE_PROFILE_PIC = "/api/updatepic";
//forgot password password update
export const UPDATE_PASSWORD = "/api/userpasswordupdate";

export const ADD_INVITATION_ITEM = "/api/postinvitation";

export const UPDATE_CLINIC_LIST = "/api/updateClinicProfile";

export const GET_USER_CLINIC_DETAILS = "/api/fetchUserClinicList";

// Updating existing user
export const UPDATE_EXSITING_USER = "/api/updateexsitingUser";

// verify user phone number during register
export const VERIFY_USER_PHONE_NUMBER = "/api/verifyuserphonenumber";
export const RESEND_OTP_NEWUSER = "/api/resendotpnewuser";
export const CONFIRM_NEWUSER_PHONENUMBER_OTP = "/api/confirmnewuserotp";
//user login data
export const USER_LOGIN_DATA = "/api/userlogindata";

//email verification for inviting user
export const CHECK_EMAIL = "/api/emailverification";

//Send sharing request
export const SEND_SHARING_REQ = "/api/sharing/";

//Accept sharing request
export const ACCEPT_SHARING_REQ = "/api/sharing/accept/";

//Revoke sharing request
export const REVOKE_SHARING_REQ = "/api/sharing/revoke/";

//Cancel sharing request
// export const CANCEL_SHARING_REQ = "/api/sharing/cancel/";

//fetch sharing accepted
export const ACCEPTED_SHARING = "/api/sharing/accepted";
//fetch profile by quickbloxID
export const GET_PROFILE_QUICKID = "/api/getProfileByQBID";
// export const CHECK_EMAIL = "/api/emailverification";

// export const FETCH_USER_PROFILE_DETAILS = "/api/fetchUserDetails";
//signup route
export const SIGNUP = "/api/signup";
export const FETCH_USER_PROFILE_DETAILS = "/api/fetchUserDetails";

/* user profile update----------2 */
export const USER_PROFILE_UPLOAD = "/api/UserProfileUpload";

/* 
clinic profile update */
export const CLINIC_PROFILE_PICTURE_UPDATE = "/api/ClinicProfileUpdate";
//notification settings
export const ADD_NOTIFICATION_SETTING = "/api/notificationSettings";

// Load Profiles
export const LOAD_PROFILE = "/api/getProfile/";
export const GET_NOTIFICATION = "/api/notificationSummary";

// help center route
export const HELP_CENTER_API = "/api/helpCenter";
//set therapy goals
export const UPDATE_DEVICE_SETTINGS = "/api/therapyGoalsSettings";

export const LOAD_DEVICE_SETTINGS = "/api/therapyGoals";

// checking exitimg account number
export const EXITING_ACCOUNT_NUMBER = "/api/exitingaccountnumber";
//post patient report search
export const PATIENT_REPORT_SEARCH = "/api/reportSearch";
// add note
export const ADD_NOTE = "/api/addnote";
// fetch note
export const FETCH_NOTE = "/api/fetchnote";
// resend Invitation
export const RESEND_INVITATION = "/api/resendinvitation";

//active care site admin by dhanush
export const ACTIVE_CARE_SITE_ADMIN = "/api/activeadminCareSite";

//invitation search by dhanush
export const INVITATION_SEARCH = "/api/invitationSearch";

//clinic site by dhanush
export const ACTIVE_CLINIC_SITE = "/api/activeClinicianSite";

export const ROUTES_ADHERANCE_SCORE_MAP = "/api/adheranceScoreMap";

export const ROUTES_ADHERANCE_SCORE_GRAPH = "/api/adheranceScoreGraph";

export const ROUTES_ADHERANCE_SCORE_DATERANGE =
  "/api/adheranceScoreMapDateRange";

export const ROUTES_HMR_GRAPH = "/api/hmr";

export const ROUTES_HMR_GRAPH_DATERANGE = "/api/hmrDateRange";

// export const RESEND_INVITATION = "/api/resendinvitation";

export const DASHBOARD = "/api/dashanalysis/";

// verify Link
export const LINK_VERIFICATION = "/api/linkverification";
// revoke user
export const REVOKE_USER = "/api/revokeuser";
export const REVOKE_USERS = "/api/revokeusers";

//Dashboard

export const TOTAL_TRANSMITTED = "/api/dashanalysis/totaltransmitted";
export const WITH_DEVIATION = "/api/dashanalysis/withdeviation";
export const WITHOUT_DEVIATION = "/api/dashanalysis/withoutdeviation";
export const NEVER_TRANSMITTED = "/api/dashanalysis/nevertransmitted";
export const ADS_U75 = "/api/dashanalysis/adsu75";
export const ADS_U50 = "/api/dashanalysis/adsu50";
export const ADS_U25 = "/api/dashanalysis/adsu25";
export const UPDATE_THERAPY_GOALS = "/api/dashanalysis/updatetherapygoals";
//missed therapy goals
export const MISSED_THERAPY = "/api/dashanalysis/missedtherapy";
// user pending req
export const USER_PENDING_REQ = "/api/pendingrequest";
// password verification
export const PASSWORD_VERIFICATION = "/api/pswrdverify";
// user new email
export const NEW_EMAIL = "/api/usernewemail";
// confirm email otp
export const CONFIRM_EMAIL_OTP = "/api/confirmemailotp";
export const CONFIRM_FORGOTEMAIL_OTP = "/api/confirmforgotemailotp";
// user new phone
export const NEW_PHONE = "/api/usernewphone";
// confirm phone otp
export const CONFIRM_PHONE_OTP = "/api/confirmphoneotp";
// update password
export const CHANGE_PASSWORD = "/api/updatepassword";

//Notification Summary Table
export const NOTIFICATION_SUMMARY_TABLE =
  "/api/notificationsummary/patienttable";
// Accept Invitation
export const ACCEPT_INVITATION = "/api/acceptinvitation";
// Reject Invitation
export const REJECT_INVITATION = "/api/rejectinvitation";
//pagination, search, display for invitation table
export const INVITATION_PAGE_LIST = "/api/invitationpagelist";
export const CHECK_IF_DEVICE_IS_CONNECTED = "/api/checkifconnectedtodevice";

export const GET_PATIENT_STATUS = "/api/patientstatus";
export const INACTIVE_PATIENT = "/api/incativePatProfile";
// Notification Summary patient report
export const NOTIFICATION_SUMMARY_PATIENT_REPORT =
  "/api/notificationsummary/patientreport";

//Get profile for profile update

export const GET_PROFILE_FOR_UPDATE = "/api/updateprofile/getprofile";

// enrolled clinician and patient count
export const ENROLLED_COUNT = "/api/enrolledcount";
// active patient lsit
export const ACTIVE_PATIENT_LIST = "/api/activepatientlist";
//active clinician list
export const ACTIVE_CLINICIAN_LIST = "/api/activeclinicianlist";
// active clinic list
export const ACTIVE_CLINICADMIN_LIST = "/api/activeclinicList";
// search on clinic list
export const SEARCH_ON_CLINIC = "/api/fetchClinicByID";
// search on clinician list
export const SEARCH_ON_CLINICIAN = "/api/searchonclinicianlist";
// fetch details for abmadmindashboard
export const SEARCH_FOR_ABMADMINDASH = "/api/searchpatientforsuperadmin";
//fetch clinic details by ID
export const FETCH_CLINIC = "/api/fetchClinicbyID";
//search on patient list
export const SEARCH_ON_PATIENT = "/api/searchonpatientlist";
// Inactive Patient list
export const INACTIVE_PATIENT_LIST = "/api/inactivepatientlist";
// all patient list
export const ALL_PATIENT_LIST = "/api/caresiteuserlist";

//patient report progress bar details
export const PATIENT_ADERANCESCORE_PROGRESSBAR_DETAILS =
  "/api/patientreport/progressbardetails";
//patient report therapy  details graph
export const PATIENT_PROFILE_THERAPY_DETAILS_GRAPH =
  "/api/patientreport/therapygraph";

export const DOWNLOAD_PATIENT_ADHERE_SCORE_HISTORY =
  "/api/patient-report/download-adhere-history";
export const DOWNLOAD_PATIENT_TRANSMISSION =
  "/api/patient-report/download-patient-transmission";
//fetching number of clinics, patients, clinicians for superadmin dashboard
export const SUPERADMIN_DASHBOARD_ENROLLED_LIST_COUNT =
  "/api/enrolleddashboardlist";

//pagination, search, display for caresite table for superadmin and abmadmin
export const SUPERADMIN_DASHBOARD_CARESITE_LIST = "/api/fetchcaresitelist";
export const GET_CARESITE_LIST_FOR_INVITATION = "/api/get-caresite-list";

export const EDIT_PATIENT_ID = "/api/edit-patient-id";

//done by poovarasan
export const CARE_SITE_DETAILS = "/api/cliniccaresitedetails";

export const GET_SENT_RECEIVED_INVITATION_COUNT =
  "/api/get-sent-received-invitaion-count";

export const RESEND_OTP_LOGIN = "/api/resendotpLogin";
export const RESEND_OTP_FORGOT = "/api/resendotpforgot";
export const RESEND_OTP_FORGOTEMAIL = "/api/resendotpforgotemail";

export const RESEND_OTP_REGISTER = "/api/resendOtpRegister";

export const UPLOAD_CSV_ENCRYPTED_FILE = "/api/decrypt-csv";
export const GEN_THERAPY_TABLE_FROM_CSV = "/api/gen-therapy-table";

export const CHECK_USERNAME = "/api/check-username";

//measuring units value
export const MEASURING_UNITS = "/api/measuringUnit";

//Fetching particular patient user

export const REPORT_SEARCH_USER = "/api/singlepatientuser";

export const RESEND_REJECTED_INVITATION = "/api/resend-rejected-invitation";

export const FETCH_DEVICE_STATS_ROUTE = "/api/device_stats";
