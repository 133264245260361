import React, { forwardRef, useRef, useState, useImperativeHandle } from 'react';
import Plot from 'react-plotly.js';
import jsPDF from 'jspdf';
import "jspdf-autotable";
import html2canvas from 'html2canvas';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import DeleteIcon from '@material-ui/icons/Delete';
import './styles.css';
import logoR from './abm.png'
import logoL from './arc.png'
import moment from 'moment';
import { Add, Download, ZoomIn, Delete } from '@mui/icons-material';
const MultiSubplotsStacked = forwardRef(({ setLoadingAutoPdf
    , data, hidePreview, startDate, endDate, averageValues, serialNumber }, ref) => {

    const plotContainerRef = useRef(null);
    const [reportImages, setReportImages] = useState([]);
    const [summaries, setSummaries] = useState([]);
    const [updatingReport, setUpdatingReport] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [zoomLevel, setZoomLevel] = useState(1);
    const [sidebarWidth, setSidebarWidth] = useState(30);
    const generateSummary = (data) => {
        console.log(data)
        const peakFlow = data.p_f_data.datasets[0].data;
        const peakFlowDates = data.p_f_data.labels;
        const expiratoryVolume = data.e_v_data.datasets[0].data;
        const cycles = data.cycle_count_data.datasets[0].data;
        const hourMeter = data.hmr_data.datasets[0].data;
        const serialNumber = data.device_serial_num;
        const reportDate = data.report_generated_date;

        const maxPeakFlow = Math.max(...peakFlow.map(Number));
        const minPeakFlow = Math.min(...peakFlow.map(Number));
        const maxExpiratoryVolume = Math.max(...expiratoryVolume.map(Number));
        const minExpiratoryVolume = Math.min(...expiratoryVolume.map(Number));
        const totalCycles = cycles.reduce((sum, cycle) => sum + cycle, 0);
        const lastHourMeter = hourMeter[hourMeter.length - 1];

        const summary = `
        Device Serial Number: ${serialNumber}.
        Report Generated Date: ${reportDate}.
        
        The peak expiratory flow ranged from ${minPeakFlow} to ${maxPeakFlow} LPM over the period from ${peakFlowDates[0]} to ${peakFlowDates[peakFlowDates.length - 1]}.
        
        Expiratory volume varied between ${minExpiratoryVolume} and ${maxExpiratoryVolume} mL during the same period.
        
        The total number of cycles recorded was ${totalCycles}, and the last hour meter reading was ${lastHourMeter} minutes.
    `;

        return summary;
    }
    const generateSingleParagraphSummary = (data) => {
        const inspiratoryPeakPressure = data.P_IP[0].y; // Inspiratory Peak Pressure values
        const cycleInspiratoryPressure = data.Cycle_I_P[0].y; // Cycle Inspiratory Pressure values
        const cycleExpiratoryPressure = data.Cycle_E_P[0].y; // Cycle Expiratory Pressure values
        const expiratoryVolume = data.E_V[0].y; // Expiratory Volume values
        const peakExpiratoryFlow = data.P_F[0].y; // Peak Expiratory Flow values
        const timestamps = data.P_IP[0].x; // Corresponding timestamps

        // Initialize tracking variables
        let highPIPEvents = [];
        let expiratoryPressureEvents = [];
        let lowExpiratoryVolumeEvents = [];
        let lowPeakFlowEvents = [];

        // Thresholds
        const highPIPThreshold = 40;
        const lowExpiratoryPressureThreshold = -50;
        const lowExpiratoryVolumeThreshold = 5000;
        const lowPeakFlowThreshold = 250;

        // Iterate through the dataset to detect events
        for (let i = 0; i < inspiratoryPeakPressure.length; i++) {
            if (inspiratoryPeakPressure[i] > cycleInspiratoryPressure[i]) {
                highPIPEvents.push({
                    timestamp: timestamps[i],
                    pip: inspiratoryPeakPressure[i],
                    cycleIP: cycleInspiratoryPressure[i]
                });
            }

            if (cycleExpiratoryPressure[i] <= lowExpiratoryPressureThreshold) {
                expiratoryPressureEvents.push({
                    timestamp: timestamps[i],
                    expPressure: cycleExpiratoryPressure[i]
                });
            }

            if (expiratoryVolume[i] <= lowExpiratoryVolumeThreshold) {
                lowExpiratoryVolumeEvents.push({
                    timestamp: timestamps[i],
                    expVolume: expiratoryVolume[i]
                });
            }

            if (peakExpiratoryFlow[i] <= lowPeakFlowThreshold) {
                lowPeakFlowEvents.push({
                    timestamp: timestamps[i],
                    peakFlow: peakExpiratoryFlow[i]
                });
            }
        }

        // Start building the summary paragraph
        let summary = "During the therapy session, several important respiratory events were observed. ";

        // High Inspiratory Peak Pressure Events
        if (highPIPEvents.length > 0) {
            const firstEvent = highPIPEvents[0];
            summary += `At ${firstEvent.timestamp}, the inspiratory peak pressure ${firstEvent.pip} cmH2O exceeded the inspiratory cycle pressure ${firstEvent.cycleIP} cmH2O. `;
        }

        // Abnormal Expiratory Pressure Events
        if (expiratoryPressureEvents.length > 0) {
            const firstEvent = expiratoryPressureEvents[0];
            summary += `At ${firstEvent.timestamp}, the expiratory pressure ${firstEvent.expPressure} cmH2O dropped below the safe threshold, suggesting potential air trapping. `;
        }

        // Low Expiratory Volume Events
        if (lowExpiratoryVolumeEvents.length > 0) {
            const firstEvent = lowExpiratoryVolumeEvents[0];
            summary += `At ${firstEvent.timestamp}, the expiratory volume ${firstEvent.expVolume} mL dropped below the normal range, indicating potential hypoventilation. `;
        }

        // Low Peak Expiratory Flow Events
        if (lowPeakFlowEvents.length > 0) {
            const firstEvent = lowPeakFlowEvents[0];
            summary += `At ${firstEvent.timestamp}, the peak expiratory flow ${firstEvent.peakFlow} L/min was significantly reduced, indicating possible airway obstruction. `;
        }

        if (summary === "During the therapy session, several important respiratory events were observed. ") {
            summary += "No significant respiratory anomalies were detected.";
        }

        return summary.trim();
    };


    const addToReport = () => {
        setUpdatingReport(true);
        if (plotContainerRef.current) {
            html2canvas(plotContainerRef.current, { scale: 2 })
                .then((canvas) => {
                    const imgData = canvas.toDataURL('image/png');
                    setReportImages([...reportImages, imgData]);
                    setSummaries([...summaries, generateSingleParagraphSummary(data)])
                    setTimeout(() => {
                        setUpdatingReport(false);
                    }, 10);
                })
                .catch((err) => {
                    console.error("Error capturing chart:", err);
                });
        }
    };
    // Expose the function to the parent via the ref
    useImperativeHandle(ref, () => ({
        callChildFunction: downloadReport,
        addToReport,
    }));
    const downloadReport = async () => {
        setLoadingAutoPdf(true); // Start loading


        const pdf = new jsPDF('p', 'pt', 'a4');
        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();
        const margin = 40;

        // Add Header with Logos and Text
        function addHeaderWithLogosAndText(pdf) {
            const logoWidth = 50;
            const logoHeight = 50;
            pdf.addImage(logoL, 'PNG', margin, 20, logoWidth, logoHeight);
            pdf.addImage(logoR, 'PNG', pageWidth - margin - logoWidth - 10, 20, logoWidth + 10, logoHeight + 10);
            pdf.setFontSize(18);
            pdf.text("BiWaze Cough Report", pageWidth / 2, 50, { align: "center" });
            pdf.setFontSize(12);
            pdf.text("Generated by Arc Connect", pageWidth / 2, 70, { align: "center" });
        }

        // Add Watermark Logo
        function addWatermarkLogo(pdf) {
            const logoWidth = 250;
            const logoHeight = 150;
            const centerX = (pageWidth - logoWidth) / 2;
            const centerY = (pageHeight - 20 - logoHeight) / 2;
            pdf.setGState(new pdf.GState({ opacity: 0.5 })); // Set reduced opacity
            pdf.addImage(logoL, 'PNG', centerX, centerY, logoWidth, logoHeight);
            pdf.setGState(new pdf.GState({ opacity: 1 })); // Reset opacity
        }

        // Add Footer
        function addFooter(pdf, pageNumber) {
            pdf.setFontSize(10);
            pdf.text("Confidential Report - Do not distribute", margin, pageHeight - 20);
            pdf.text(`Page: ${pageNumber}`, pageWidth - margin, pageHeight - 20, { align: "right" });
        }

        // Add Date Range
        function addDateRange(pdf, startDateTime, endDateTime) {
            const options = { year: 'numeric', month: 'long', day: 'numeric' };
            console.log(data.Cycle_P_P[0]?.x[0])
            console.log((data.Cycle_P_P[0]?.x[data.Cycle_P_P[0]?.x?.length - 1]))
            const startDate = new Date(data.Cycle_P_P[0]?.x[0]).toLocaleDateString('en-US', options);
            const endDate = new Date(data.Cycle_P_P[0]?.x[data.Cycle_P_P[0]?.x.length - 1]).toLocaleDateString('en-US', options);

            pdf.setFontSize(12);
            if (startDate === endDate) {
                pdf.text("Therapy Date", pageWidth / 2, 110, { align: "center" });
                pdf.text(`${startDate} `, pageWidth / 2, 130, { align: "center" });
            } else {
                pdf.text("Therapy Date Range", pageWidth / 2, 110, { align: "center" });
                pdf.text(`${startDate} to ${endDate}`, pageWidth / 2, 130, { align: "center" });
            }
        }

        function addAbbrevationsUnits(pdf) {
            const abbreviationsDetails = [
                ["Peak Expiratory flow", "lpm"],
                ["Expiratory Volume", "ml"],
                ["Peak Inspiratory pressure", "cmH2O/mbar/hPa"],
                ["No. of cycles", "Cycles"],
                ["Pause Pressure", "cmH2O/mbar/hPa"],
                ["Pause time", "seconds"],
                ["Inspiratory Pressure", "cmH2O/mbar/hPa"],
                ["Inspiratory time", "seconds"],
                ["Expiratory Pressure", "cmH2O/mbar/hPa"],
                ["Expiratory time", "seconds"],
                ["Oscillation feature", "0: Off, 1: On, 2: Only Insp On, 3: Only Exp On"],
                ["Inspiratory Flow", "High/Low/Medium"],
                ["Oscillation Amplitude", "NA"],
                ["Oscillation frequency", "Hz"],
                ["Insp Trigger", "NA"],
                ["Inspiratory trigger sensitivity", "NA"],
                ["Hour meter reading", "hh:mm:ss"]
            ]
                ;
            pdf.autoTable({
                head: [["Description", "Unit"]],
                body: abbreviationsDetails,
                startY: 150,
                styles: {
                    fontSize: 10,
                    halign: "center",
                    cellPadding: 6,
                    overflow: "linebreak",
                },
                headStyles: {
                    fillColor: [51, 122, 183],
                    textColor: 255,
                    fontSize: 12,
                    fontStyle: "bold",
                },
            });
        }
        function addTableAndSummary(pdf) {
            addHeaderWithLogosAndText(pdf); // Add header
            addDateRange(pdf, startDate, endDate); // Add date range

            const productDetails = [
                ["Ref Number/Model", serialNumber, "Hour Meter Reading", averageValues["HMR"]]
            ];

            const statsDetails = [
                ["Average therapy cycles per session", averageValues["average_NC"], "Average Expiratory Volume", averageValues["average_EV"]],
                ["Average therapy sessions per day", averageValues["average_Therapy"], "Average Peak Cough Flow", averageValues["average_PF"]],
                ["Number of days with no therapy", averageValues["noDaysWithoutTherapy"], "", ""]
            ];

            pdf.autoTable({
                body: productDetails,
                startY: 150,
                styles: {
                    fontSize: 12,
                    halign: "center",
                    cellPadding: 8,
                    overflow: "linebreak",
                },
                headStyles: {
                    fillColor: [240, 240, 240],
                    textColor: 0,
                    fontSize: 12,
                    fontStyle: "bold",
                },
            });

            pdf.autoTable({
                body: statsDetails,
                startY: pdf.previousAutoTable.finalY + 20,
                styles: {
                    fontSize: 12,
                    halign: "center",
                    cellPadding: 8,
                    overflow: "linebreak",
                },
            });

        }
        const unitRanges = [
            ["Set Pause Pressure", "0 to 15", "Peak Inspiratory Pressure", "Depends on Set Pressures"],
            ["Set Inspiratory Pressure", "0 to 70 ", "Peak Flow", "Depends on user"],
            ["Set Expiratory Pressure", "-70 to 0", "Expiratory volume", "NA"]
        ];
        // First Page
        addWatermarkLogo(pdf);
        addTableAndSummary(pdf);

        pdf.autoTable({
            head: [["Set Pressures", "Range(cmH20)", "Delivered Pressures", "Range"]],
            body: unitRanges,
            startY: pdf.previousAutoTable.finalY + 200,
            styles: {
                fontSize: 12,
                halign: "center",
                cellPadding: 8,
                overflow: "linebreak",
            }, headStyles: {
                fillColor: [51, 122, 183],
                textColor: 255,
                fontSize: 12,
                fontStyle: "bold",
            },
        });
        addFooter(pdf, 1);

        if (reportImages.length === 0) {
            if (plotContainerRef.current) {
                try {
                    // Wait for the html2canvas promise to resolve
                    const canvas = await html2canvas(plotContainerRef.current, { scale: 2 });
                    const imgData = canvas.toDataURL('image/png');
                    setReportImages([...reportImages, imgData]);
                    setSummaries([...summaries, generateSingleParagraphSummary(data)]);
                    pdf.addPage();
                    addHeaderWithLogosAndText(pdf);
                    addWatermarkLogo(pdf);

                    pdf.setFontSize(14);
                    pdf.addImage(imgData, 'PNG', margin, 120, 500, 500);

                    pdf.setFontSize(9);
                    const lines = pdf.splitTextToSize(generateSingleParagraphSummary(data), pageWidth - margin * 2);
                    pdf.text(lines, margin, 650);

                    addFooter(pdf, 0 + 2);
                    setUpdatingReport(false); pdf.save('BiWaze_therapy_report.pdf'); setLoadingAutoPdf(false);

                } catch (err) {
                    console.error("Error capturing chart:", err);
                }
            }
        }
        else {
            reportImages.forEach((imgData, index) => {
                pdf.addPage();
                addHeaderWithLogosAndText(pdf);
                addWatermarkLogo(pdf);

                pdf.setFontSize(14);
                pdf.addImage(imgData, 'PNG', margin, 120, 500, 500);

                pdf.setFontSize(9);
                const lines = pdf.splitTextToSize(summaries[index], pageWidth - margin * 2);
                pdf.text(lines, margin, 650);

                addFooter(pdf, index + 2);
            });
            pdf.addPage();
            addHeaderWithLogosAndText(pdf);
            pdf.text("Abbrevations and Units", pageWidth / 2, 120, { align: "center" });
            addWatermarkLogo(pdf);
            addAbbrevationsUnits(pdf);
            addFooter(pdf, reportImages?.length + 2);

            pdf.save('BiWaze_therapy_report.pdf');
            setLoadingAutoPdf(false);
        }


    };



    const handleImageClick = (img) => {
        setSelectedImage(img);
        setZoomLevel(1); // Reset zoom when opening a new image
    };

    const handleDeleteImage = (indexToDelete) => {
        setReportImages(reportImages.filter((_, index) => index !== indexToDelete));
        setSummaries(summaries.filter((_, index) => index !== indexToDelete));
    };

    const handleZoomIn = () => {
        setZoomLevel((prev) => prev + 0.2);
    };

    const handleZoomOut = () => {
        setZoomLevel((prev) => Math.max(prev - 0.2, 1));
    };

    const handleCloseDialog = () => {
        setSelectedImage(null);
    };
    const mergeIndividualTherapyValues = (data, axis) => {
        return data
            ?.slice(0, data?.P_IP?.length)
            ?.map(entry => entry[axis])

            ?.flat()
    }
    const mergeIndividualTherapyValuesAverage = (data, axis) => {
        const values = data?.slice(0, data?.P_IP?.length)?.map(entry => entry[axis])?.flat();

        if (values.length > 100) {
            // Group data by day and calculate averages
            const groupedByDay = {};
            data?.forEach(entry => {
                const date = new Date(entry.timestamp).toISOString().split('T')[0]; // Extract date in 'YYYY-MM-DD' format
                if (!groupedByDay[date]) groupedByDay[date] = { sum: 0, count: 0 };
                groupedByDay[date].sum += entry[axis];
                groupedByDay[date].count += 1;
            });

            // Map the grouped averages to arrays
            const avgPerDay = Object.keys(groupedByDay).map(date => ({
                date,
                avg: groupedByDay[date].sum / groupedByDay[date].count
            }));

            // Map values to x and y axis
            const xValues = avgPerDay.map(item => item.date);
            const yValues = avgPerDay.map(item => item.avg);

            return { x: xValues, y: yValues };
        }

        return {
            x: data?.map(entry => entry.timestamp),
            y: values,
        };
    };

    const plotData = [


        // {
        //     x: data?.P_IP[0]?.x,
        //     y: data?.P_IP[0]?.y,
        //     type: 'scatter',
        //     mode: 'lines+markers',
        //     name: 'Peak Insp Pressure',
        //     line: { color: '#9467bd' },
        //     yaxis: 'y',
        // },
        // {
        //     x: data.Cycle_P_P[0]?.x,
        //     y: data.Cycle_P_P[0]?.y,
        //     type: 'bar',
        //     name: 'Set Pause Pressure',
        //     marker: {
        //         color: data.Cycle_P_P[0]?.y.map((_, i) =>
        //             data.Cycle_P_P[0]?.o &&
        //                 (data.Cycle_P_P[0].o[i] === 0 || data.Cycle_P_P[0].o[i] === 1 || data.Cycle_P_P[0].o[i] === 2 || data.Cycle_P_P[0].o[i] === 3)
        //                 ? 'rgba(68, 187, 68, 0.8)'  // Color with pattern if `o` conditions are met
        //                 : 'rgba(150, 150, 150, 0.3)' // Base color for bars without `o` or when condition isn’t met
        //         ),
        //         line: {
        //             color: 'rgba(68, 187, 68, 1)', // Outline color for all bars
        //             width: 1,
        //         },
        //         pattern: {
        //             shape: data.Cycle_P_P[0]?.o
        //                 ? data.Cycle_P_P[0].o.map((oValue) =>
        //                     (oValue === 1 || oValue === 2) ? '/' : '' // Pattern only when `o` exists and conditions are met
        //                 )
        //                 : Array(data.Cycle_P_P[0]?.y.length).fill(''), // No pattern if `o` is undefined
        //             size: 5,
        //             solidity: 0.4,
        //             fgcolor: data.Cycle_P_P[0]?.o
        //                 ? data.Cycle_P_P[0].o.map((oValue) =>
        //                     (oValue === 1 || oValue === 2) ? 'rgba(0, 128, 0, 0.7)' : 'rgba(150, 150, 150, 0.5)'
        //                 )
        //                 : Array(data.Cycle_P_P[0]?.y.length).fill('rgba(150, 150, 150, 0.5)'), // Default color if `o` is undefined
        //             bgcolor: 'rgba(68, 187, 68, 0.5)',
        //         },
        //     },
        //     yaxis: 'y1',
        // },
        {
            x: data.Cycle_P_P[0]?.x,
            y: data.Cycle_P_P[0]?.y,
            type: 'bar',
            name: 'Set Pause Pressure',
            marker: {
                color: 'rgba(68, 187, 68, 0.8)',
                line: {
                    color: 'rgba(68, 187, 68, 1)',
                    width: 1,
                },
                pattern: {
                    shape: (data.Cycle_P_P[0]?.o || []).map((oValue) =>
                        (oValue === 1 || oValue === 2) ? '/' : ''  // Pattern only for certain `o` values
                    ) || '',
                    size: 10,
                    solidity: 0.3,
                    fgcolor: (data.Cycle_P_P[0]?.o || []).map((oValue) =>
                        (oValue === 1 || oValue === 2) ? 'rgba(0, 128, 0, 0.7)' : 'rgba(68, 187, 68, 0.8)'
                    ),
                    bgcolor: 'rgba(68, 187, 68, 0.5)'
                },
            },
            yaxis: 'y1',
        },


        {
            x: data.Cycle_I_P[0]?.x,
            y: data.Cycle_I_P[0]?.y,
            type: 'bar',
            name: 'Set Insp Pressure',
            marker: {
                color: 'rgb(20, 97, 211, 0.8)',
                line: {
                    color: 'rgb(20, 97, 211, 1)',
                    width: 1,
                },
                pattern: {
                    shape: data.Cycle_I_P[0]?.o.map((oValue) =>
                        (oValue === 1 || oValue === 2) ? '/' : ''  // Pattern only for certain `o` values
                    ) || '',
                    size: 10,
                    solidity: 0.3,
                    fgcolor: data.Cycle_I_P[0]?.o.map((oValue) =>
                        (oValue === 1 || oValue === 2) ? 'rgba(20, 128, 211, 0.7)' : 'rgba(20, 97, 211, 0.8)'
                    ),
                    bgcolor: 'rgba(20, 97, 211, 0.5)'
                },
            },

            yaxis: 'y1',
        },


        {
            x: data.Cycle_E_P[0]?.x,
            y: data.Cycle_E_P[0]?.y,
            type: 'bar',
            name: 'Set Exp Pressure',
            marker: {
                color: 'rgb(255, 174, 66,0.8)',
                line: {
                    color: 'rgb(255, 174, 66, 1)',
                    width: 1,
                },
                pattern: {
                    shape: data.Cycle_E_P[0]?.o.map((oValue) =>
                        (oValue === 1 || oValue === 3) ? '/' : ''  // Pattern only for certain `o` values
                    ) || '',
                    size: 10,
                    solidity: 0.3,
                    fgcolor: data.Cycle_E_P[0]?.o.map((oValue) =>
                        (oValue === 1 || oValue === 3) ? 'rgba(255, 128, 66, 0.7)' : 'rgba(255, 174, 66, 0.8)'
                    ),
                    bgcolor: 'rgba(255, 174, 66, 0.5)'
                },
            },
            yaxis: 'y1',
        },
        //For Dynamic Individual Graphs
        // ...data.P_F.map((item, index) => {
        //     if (!hidePreview || !item || !item.x || !item.y) return null;

        //     return {
        //         x: item.x,
        //         y: item.y,
        //         type: 'scatter',
        //         mode: 'lines+markers',
        //         name: `${item.ref_id}Peak Flow`,
        //         line: { color: 'grey', dash: 'dash' },
        //         yaxis: 'y2',
        //     }
        // }),
        {
            x: mergeIndividualTherapyValues(data.P_F, 'x'),
            y: mergeIndividualTherapyValues(data.P_F, 'y'),
            type: 'scatter',
            mode: 'lines+markers',
            name: 'Peak Flow',
            line: { color: 'grey', dash: 'dash' },
            yaxis: 'y2',
        },
        // {
        //     x: mergeIndividualTherapyValues(data?.P_F, "x"),
        //     y: mergeIndividualTherapyValues(data?.P_F, "y"),
        //     type: 'scatter',
        //     mode: 'lines+markers',
        //     name: 'Peak Flow',
        //     line: { color: 'grey', dash: 'dash' },
        //     yaxis: 'y2',
        // },
        // ...(data?.P_IP?.[0]?.x || []).map((xValue, index) => ({
        //     // x: [
        //     //     moment(xValue, "YYYY-MM-DD HH:mm:ss.SSS").subtract(2, 'milliseconds').format("YYYY-MM-DD HH:mm:ss.SSS"),
        //     //     moment(xValue, "YYYY-MM-DD HH:mm:ss.SSS").add(2, 'milliseconds').format("YYYY-MM-DD HH:mm:ss.SSS")

        //     // ],
        //     x: data?.P_IP[0]?.x,

        //     y: [data.P_IP[0].y[index], data.P_IP[0].y[index]],
        //     type: 'bar',
        //     mode: 'lines',
        //     name: 'Peak Insp Pressure',  // This will show up in the legend only once
        //     showlegend: index === 0,  // Show legend only for the first trace
        //     legendgroup: 'red-lines',         // Group all red lines under the same legend group

        //     line: { color: 'red', width: 2 },
        //     yaxis: 'y',
        // })),
        // {
        //     x: data?.P_IP
        //         ?.slice(0, data?.P_IP?.length)
        //         ?.map(entry => entry?.x)
        //         ?.flat(),
        //     y: data?.P_IP
        //         ?.slice(0, data?.P_IP?.length)
        //         ?.map(entry => entry?.y)
        //         ?.flat(),
        //     type: 'box',
        //     mode: 'lines',
        //     name: 'Peak Insp Pressure',
        //     line: { color: 'red', dash: 'dash' },
        //     yaxis: 'y1',
        //     // marker: {
        //     //     color: 'rgba(255, 0, 0, 0.8)', // Marker color
        //     // },
        //     hoverinfo: 'x+y+name', // Show x, y values and the trace name on hover
        //     // boxpoints: 'outliers', hoverinfo: 'x+y+name', // Display x, y values and name on hover
        //     boxpoints: 'all',      // Show all points
        //     jitter: 1,           // Spread points horizontally for better visibility
        //     pointpos: 0.5,
        // },
        {
            x: mergeIndividualTherapyValues(data.P_IP, 'x'),
            y: mergeIndividualTherapyValues(data.P_IP, 'y'),
            type: 'box',
            mode: 'lines',
            name: 'Peak Insp Pressure',
            line: { color: 'red', dash: 'dot' },
            yaxis: 'y1',
            // marker: {
            //     color: 'rgba(255, 0, 0, 0.8)', // Marker color
            // },
            hoverinfo: 'y+name', // Show x, y values and the trace name on hover
            // boxpoints: 'outliers', hoverinfo: 'x+y+name', // Display x, y values and name on hover
            boxpoints: 'all',      // Show all points
            // jitter: 1,           // Spread points horizontally for better visibility
            pointpos: 0.1,
        },
        {
            x: mergeIndividualTherapyValues(data.E_V, 'x'),
            y: mergeIndividualTherapyValues(data.E_V, 'y'),
            type: 'scatter',
            mode: 'lines+markers',
            name: 'Exploratory Volume (ml)',
            line: { color: 'blue', dash: 'dash' },
            yaxis: 'y3',
        },
        // Wiggles
        // ...data.Cycle_I_P[0].x.map((xValue, index) => {
        //     const { x, y } = generateWavyLine(xValue, data.Cycle_I_P[0].y[index]);
        //     return {
        //         x,
        //         y,
        //         type: 'scatter',
        //         mode: 'lines',
        //         showlegend: false,
        //         line: { color: '#1461d3', width: 1 },
        //         yaxis: 'y1',
        //     };
        // }),
    ];
    const filterValidData = (xData, yData) => {
        const filteredPoints = xData.map((x, index) => ({
            x: x,
            y: yData[index],
        })).filter(point => point.y !== null && point.y !== undefined && point.y !== '');

        return {
            x: filteredPoints.map(point => point.x),
            y: filteredPoints.map(point => point.y),
        };
    };

    // Preprocessing data
    // const processedCyclePP = filterValidData(data.Cycle_P_P[0]?.x, data.Cycle_P_P[0]?.y);
    // const processedCycleIP = filterValidData(data.Cycle_I_P[0]?.x, data.Cycle_I_P[0]?.y);
    // const processedCycleEP = filterValidData(data.Cycle_E_P[0]?.x, data.Cycle_E_P[0]?.y);
    // const processedPF = filterValidData(data?.P_F[0]?.x, data?.P_F[0]?.y);
    // const processedEV = filterValidData(data?.E_V[0]?.x, data?.E_V[0]?.y);
    const bufferMs = 5 * 60 * 10; // 5 minutes in milliseconds

    // const xValues = data.P_F.flatMap(item =>
    //     item.x.map(timestamp => Date.parse(timestamp)) // Convert timestamp strings into numbers
    // ).filter(x => !isNaN(x));
    // const xMin = Math.min(...xValues);
    // const xMax = Math.max(...xValues);
    function calculateCategoryTickvals(categories, maxTicks) {
        const step = Math.ceil(categories.length / maxTicks);
        return categories.filter((_, index) => index % step === 0);
    }

    const xValues = data.P_F.flatMap(item => item.x);
    const maxTicks = 10; // Define the maximum number of visible ticks
    const tickvals = calculateCategoryTickvals(xValues, maxTicks);

    function calculateDynamicDtick(minY, maxY, desiredTicks = 100) {
        const range = maxY - minY;

        // Avoid division by zero or nonsensical values
        if (range <= 0) return 1;

        // Calculate raw interval
        const rawInterval = range / desiredTicks;

        // Round interval to the nearest "nice" value (e.g., 1, 2, 5, 10)
        const magnitude = Math.pow(10, Math.floor(Math.log10(rawInterval)));
        const niceInterval = Math.ceil(rawInterval / magnitude) * magnitude;

        return niceInterval;
    }

    const yValues = data.E_V[0]?.y ?? [];
    const minY = Math.min(...yValues);
    const maxY = Math.max(...yValues);
    const dynamicDtick = calculateDynamicDtick(minY, maxY);
    const layout = {
        // barmode: 'stack',
        width: 1300,
        xaxis: {
            // title: 'Date time (HH:MM:SS)',
            // tickformat: '%H:%M', // Show only hours and minutes
            showgrid: true,                  // Enable grid lines for better readability
            tickangle: 45,
            // dtick: dynamicDtick, // Set interval to 1 hour (in milliseconds)
            // range: [xMin - bufferMs, xMax + bufferMs], // Ensure x axis only shows valid range
            tickvals: tickvals, // Use exact x values
            type: 'category', // Treat x values as categories to prevent interpolation

            // tickvals: [...data?.Cycle_I_P[0]?.x],
        },
        yaxis: {
            title: 'Pressure (cmH2O)',
            side: 'left',
            position: 0,
            range: [-90, 90],
            autorange: true, // Automatically determine the range based on data
        },
        yaxis2: {
            title: 'Flow (lpm)',
            overlaying: 'y',
            side: 'right',
            position: 1,
            autorange: true, // Automatically determine the range based on data

            tickcolor: "red", showgrid: false, // Remove grid lines on the x-axis

        },
        yaxis3: {
            title: 'Expiratory volume (ml)',
            side: 'left',
            anchor: 'free',
            domain: [0, 0.45],
            position: 0,
            // dtick: 500,
            tickformat: '.f',
            autorange: true, // Automatically determine the range based on data
        },
        grid: {
            rows: 2, columns: 1, pattern: 'independent', roworder: 'top to bottom',
            rowgap: 0.1,
        },
        // width: 1000,
        height: 1000,
        showlegend: true,
        legend: {

            orientation: 'h',               // Horizontal orientation to allow for column styling
            x: 1,
            y: 1,                         // Position below the plot
            xanchor: 'right',
            yanchor: 'bottom',
            traceorder: 'normal',            // Keep the order of traces
            tracegroupgap: 5,                // Space between legend items
        },
    };
    return (
        <div className='graph-container'>
            <div className='plot-container' ref={plotContainerRef} >
                {data && data.P_F && data.P_F[0] && (
                    <Plot
                        style={{ width: '100%' }}
                        data={plotData}

                        layout={layout}

                        config={{ responsive: true }}
                    />
                )}
            </div>
            {hidePreview && (
                <div
                    className="sidebar"
                    style={{
                        width: `${sidebarWidth} % `,
                        backgroundColor: "#f9f9f9",
                        padding: "16px",
                        borderRadius: "12px",
                        boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                        overflowY: "auto",
                        transition: "all 0.3s ease",
                    }}
                >
                    {/* Button Group */}
                    <div
                        className="button-group"
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "16px",
                            marginBottom: "24px",
                        }}
                    >
                        {/* Add to Report Button */}
                        <Button
                            className={`custom - button ${updatingReport ? "updating" : ""}`}
                            variant="contained"
                            onClick={addToReport}
                            startIcon={
                                updatingReport ? (
                                    <span className="animate-spin">
                                        <Add sx={{ fontSize: "20px", transition: "transform 0.3s ease" }} />
                                    </span>
                                ) : (
                                    <Add sx={{ fontSize: "20px", transition: "transform 0.3s ease" }} />
                                )
                            }
                            sx={{
                                backgroundColor: "#4CAF50",
                                color: "#fff",
                                fontWeight: "bold",
                                height: "50px",
                                borderRadius: "10px",
                                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                                transition: "transform 0.2s ease, background-color 0.3s ease",
                                "&:hover": {
                                    backgroundColor: "#45A049",
                                    transform: "scale(1.03)",
                                },
                            }}
                        >
                            {updatingReport ? "Updating..." : "Copy View to Report"}
                        </Button>

                        {/* Download Report Button */}
                        <Button
                            className="custom-button"
                            variant="contained"
                            onClick={downloadReport}
                            startIcon={
                                <Download sx={{ fontSize: "20px", transition: "transform 0.3s ease" }} />
                            }
                            sx={{
                                backgroundColor: "#2196F3",
                                color: "#fff",
                                fontWeight: "bold",
                                height: "50px",
                                borderRadius: "10px",
                                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                                transition: "transform 0.2s ease, background-color 0.3s ease",
                                "&:hover": {
                                    backgroundColor: "#1E88E5",
                                    transform: "scale(1.03)",
                                },
                            }}
                        >
                            Download
                        </Button>
                    </div>

                    {/* Report List */}
                    <div className="report-list">
                        {reportImages.map((img, index) => (
                            <div className="report-item" key={index}>
                                <img src={img} alt={`Report ${index}`} />
                                <div className="image-overlay">
                                    <IconButton
                                        onClick={() => handleImageClick(img)}
                                        sx={{
                                            color: "#ffffff",
                                            transition: "transform 0.3s ease",
                                            "&:hover": { transform: "scale(1.2)" },
                                        }}
                                    >
                                        <ZoomIn sx={{ fontSize: "20px" }} />
                                    </IconButton>
                                    <IconButton
                                        onClick={() => handleDeleteImage(index)}
                                        sx={{
                                            color: "#FF5252",
                                            transition: "transform 0.3s ease",
                                            "&:hover": { transform: "scale(1.2)" },
                                        }}
                                    >
                                        <Delete sx={{ fontSize: "20px" }} />
                                    </IconButton>
                                </div>
                            </div>
                        ))}
                        {reportImages.length < 1 && (
                            <p style={{ fontSize: "14px", color: "#666666", textAlign: "center" }}>
                                Click on 'Copy View to Report' to copy the graphs and add
                                them to the report for download.
                            </p>
                        )}
                    </div>
                </div>
            )}




            {/* Dialog for image viewing */}
            <Dialog open={!!selectedImage} onClose={handleCloseDialog} maxWidth="md" >
                <DialogTitle>Image Viewer</DialogTitle>
                <DialogContent>
                    <div className="image-viewer">
                        <img src={selectedImage} alt="Selected report" style={{ transform: `scale(${zoomLevel})` }} />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleZoomIn} variant="contained">
                        Zoom In
                    </Button>
                    <Button onClick={handleZoomOut} variant="contained">
                        Zoom Out
                    </Button>
                    <Button onClick={handleCloseDialog} variant="contained" color="secondary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
});

export default MultiSubplotsStacked;
