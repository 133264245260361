// import { Roles } from "config"

/*
 * These are the placeholder roles you can replace these with yours
 */
   let roles =  {
  SUPER_ADMIN: "SUPER_ADMIN",
  ADMIN: "ADMIN",
  CLINIC: "CLINIC",
};

export default roles;
