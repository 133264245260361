import React, { useState } from "react";
import { Drawer, Checkbox, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import ReactApexChart from "react-apexcharts";

const App = () => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [chartData, setChartData] = useState({
        series: [
            {
                name: "Value",
                data: [],
            },
        ],
        options: {
            chart: {
                type: "bar",
            },
            xaxis: {
                categories: [],
            },
        },
    });

    const sampleData = [
        { id: 1, label: "January", value: 10 },
        { id: 2, label: "February", value: 20 },
        { id: 3, label: "March", value: 30 },
        { id: 4, label: "April", value: 40 },
    ];

    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    const handleRowSelect = (id) => {
        const selected = sampleData.filter((item) => id.includes(item.id));
        setSelectedRows(selected);
        setChartData({
            series: [
                {
                    name: "Value",
                    data: selected.map((item) => item.value),
                },
            ],
            options: {
                ...chartData.options,
                xaxis: {
                    categories: selected.map((item) => item.label),
                },
            },
        });
    };

    return (
        <div style={{ padding: "20px" }}>
            {/* Open Drawer Button */}
            <Button variant="contained" color="primary" onClick={toggleDrawer}>
                Open Off-Canvas
            </Button>

            {/* Drawer (Off-Canvas) */}
            <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer}>
                <div style={{ width: 300, padding: 20 }}>
                    <Typography variant="h6" gutterBottom>
                        Select Rows
                    </Typography>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Select</TableCell>
                                    <TableCell>Label</TableCell>
                                    <TableCell>Value</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sampleData.map((row) => (
                                    <TableRow key={row.id}>
                                        <TableCell>
                                            <Checkbox
                                                onChange={(e) => {
                                                    const checked = e.target.checked;
                                                    if (checked) {
                                                        handleRowSelect([...selectedRows.map((r) => r.id), row.id]);
                                                    } else {
                                                        handleRowSelect(
                                                            selectedRows.map((r) => r.id).filter((id) => id !== row.id)
                                                        );
                                                    }
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell>{row.label}</TableCell>
                                        <TableCell>{row.value}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </Drawer>

            {/* Chart Area */}
            <div style={{ marginTop: "20px" }}>
                <Typography variant="h6" gutterBottom>
                    Graph Data
                </Typography>
                <ReactApexChart options={chartData.options} series={chartData.series} type="bar" height={350} />
            </div>
        </div>
    );
};

export default App;
